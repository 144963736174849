import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import L from "leaflet";
import { Marker } from "react-leaflet";
import {
  MapContainer,
  LayersControl,
  TileLayer,
  GeoJSON,
  useMap,
  useMapEvents,
  ZoomControl,
  WMSTileLayer,
} from "react-leaflet";
import * as turf from "@turf/turf";
import "leaflet/dist/leaflet.css";
import "./css/map_view.css";
import PointData from "../assets/sec_point";
import Linedata from "../assets/sec_line";
import LeftSidebar from "./LeftSidebar";
import TopNavbar from "./TopNavbar";
import { pointsOnLine, convertClassNameString } from "../utils/map";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";
import RightSideDrawBar from "./BaseLayer/RightSideDrawBar";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-easyprint/dist/bundle";

// import L from 'leaflet';
import "leaflet-draw";
// import 'leaflet-geometryutil';
//import jsPDF from "jspdf";
import "leaflet-geometryutil";
import MapEvents from "./MapEvents";
// import PrintControl from "./PrintControl";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import domtoimage from 'dom-to-image';
// import { BiPolygon } from "react-icons/bi";

// Sabhi setState rewmove -> fun handler\

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";

import SearchLatLangString from "./utils/SearchLatLangString";
import SubstationSelector from "./utils/SubstationSelector";
import LocationSelector from "./utils/LocationSelector";
import LatLngPicker from "./utils/LatLngPicker";
import SearchLatLng from "./utils/SearchLatLng";
// import { debounce } from 'lodash';
import MarkerCluster from "./functions/MarkerClustur";
// import { fetchLineData } from "./functions/fetchLineData";
import { exportMapAsPDF } from "./functions/exportMapAsPDF";
// import { exportAllLayersAsPDF } from "./functions/exportAllLayersAsPDF";
import { fetchObjectData } from "./functions/fetchObjectData";
// import LineDataLayer from "./functions/LineDataLayer";
import Loader from "./loading/Loader";
import FindPlace from "./utils/FindPlace";
import { Value } from "sass";
// import { MarkerClusterGroup } from "leaflet";

// const triangleSVG = `
//    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="lightblue" stroke="blue" stroke-width="2">
//     <polygon points="12,2 22,22 2,22" />
//   </svg>
// `;
const customIcon1 = new L.Icon({
  iconUrl: "/dark-location-marker.svg",
  iconSize: [30, 30],
});

const customIcon = new L.Icon({
  iconUrl: markerIcon,
  shadowUrl: markerIconShadow,
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
  shadowSize: [41, 41], // Size of the shadow
  shadowAnchor: [12, 41], // Point of the shadow which will correspond to marker's location
  popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
});

const PointIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/128/447/447031.png", // Custom icon URL
  iconSize: [32, 32], // Size of the icon
  iconAnchor: [16, 32], // Point where the icon is anchored
  popupAnchor: [0, -32], // Popup position
});
const ZoomTracker = ({ setZoom }) => {
  // Hook into the map's events using useMapEvents
  useMapEvents({
    zoomend: (event) => {
      const zoomLevel = event.target.getZoom(); // Get the current zoom level
      setZoom(zoomLevel); // Update the zoom level in state
    },
  });

  return null; // This component doesn't render anything itself
};

const baseURL = process.env.REACT_APP_DRAW_API_URL;

const MapView = () => {
  const { BaseLayer } = LayersControl;
  const mapRef = useRef(null);
  // const mapRef = useRef([]);
  const geoJSONBetweenTraceRef = useRef(null);
  const [activeLayer, setActiveLayer] = useState("Canvas");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  // const [selectedFeature, setSelectedFeature] = useState(null);
  // const [selectedFeatureId, setSelectedFeatureId] = useState([]);
  const [zoom, setZoom] = useState(5);
  // const [datapoint, setDatapoint] = useState(PointData);
  const [dataLine, setDataLine] = useState(Linedata);
  // const [pointLayer, setPointLayer] = useState(null);
  // const [lineLayer, setLineLayer] = useState(null);
  const [center, setCenter] = useState([
    PointData.features[0].geometry.coordinates[1],
    PointData.features[0].geometry.coordinates[0],
  ]);
  const storedAuth = JSON.parse(localStorage.getItem("_auth"));
  const [className, setClassName] = useState("container-hide");
  const [poleClassName, setPoleClassName] = useState("pole-hide");
  const [markers, setMarkers] = useState([]);
  // const [traceBetween, setTraceBetween] = useState([]);
  // const [circles, setCircles] = useState([]);
  // const [isMeasuring, setIsMeasuring] = useState(false);

  const [points, setPoints] = useState([]);
  // const [distances, setDistances] = useState([]);
  // const [totalDistance, setTotalDistance] = useState(0);

  // const [lineColor, setLineColor] = useState("#0080ff");
  const [selectedData, setSelectedData] = useState(null);
  const [measureDetail, setMeasureDetail] = useState();

  const [isGujaratBoundary, setIsGujaratBoundary] = useState(true);
  const [loading, setLoading] = useState(false);
  const markerRef = useRef(null);
  const [isPickingLatLng, setIsPickingLatLng] = useState(false);

  const [selectedLatLng, setSelectedLatLng] = useState(null);

  const [floatingActionButton, setFloatingActionButton] = useState(false); //FAB BUTTON
  const [showModalOfLatLangString, setshowModalOfLatLangString] =
    useState(false);
  const [showModalOfLatLangSeprate, setshowModalOfLatLangSeprate] =
    useState(false);
  const [nearestSubstationhovered, setNearestSubstationhovered] =
    useState(null);
  const [pickSubstationLoc, setPickSubstationLoc] = useState(false);
  const [nearestSubstation, setNearestSubstation] = useState(null);
  const [nearestLocation, setNearestLocation] = useState(null);
  const [pickLocation, setPickLocation] = useState(false);
  const [nearestLocationhovered, setNearestLocationhovered] = useState(null);

  const gujaratBounds = [
    [19.1226, 67.1629], // Southwest coordinates (bottom-left)
    [25.3963 - 0.5, 75.4769 + 0], // Adjusted northeast coordinates
  ];

  const [selectedTaluka, setSelectedTaluka] = useState();
  const [isTalukaVisible, setIsTalukaVisible] = useState(false);
  const [geojsonData, setGeojsonData] = useState(null);
  const [geojsonTalukaData, setGeojsonTalukaData] = useState();
  // const [zindex, setZindex] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isMapVisble, setIsMapVisible] = useState(true);
  // const [measureResult, setMeasureResult] = useState(null);
  // const [drawnLayers, setDrawnLayers] = useState([]);
  const [measurementVisible, setMeasurementVisible] = useState(false);
  // const [pointGeometry, setPointGeometry] = useState(null);

  const [selectedDistrictId, setSelectedDistrictId] = useState();

  const [measureMode, setMeasureMode] = useState(null);

  const [districtStyle, setDistrictStyle] = useState({
    color: "#0B7D7D",
    opacity: 1,
    fillColor: "#008000",
    fillOpacity: "0.3",
    zIndex: 1200,
  });
  const [talukaStyle, setTalukaStyle] = useState({
    color: "#000000",
    opacity: 1,
    fillColor: "#0000FF",
    fillOpacity: "0.2",
    zIndex: 1250,
  });
  const [companyStyle, setCompanyStyle] = useState({
    color: "#000000",
    opacity: 0.7,
    fillColor: "#000000",
    fillOpacity: "0.3",
    zIndex: 1000,
  });
  const [highwayStyle, setHighwayStyle] = useState({
    color: "#6B6B6B",
    opacity: 2,
  });

  const [circleStyle, setCircleStyle] = useState({
    color: "#660f0f",
    opacity: 1,
    fillColor: "#8B0000",
    fillOpacity: "0.2",
    zIndex: 1001,
  });
  const [divisionStyle, setDivisionStyle] = useState({
    color: "#402e03",
    opacity: 1,
    fillColor: "#B8860B",
    fillOpacity: "0.2",
    zIndex: 1002,
  });
  const [subDivisionStyle, setSubDivisionStyle] = useState({
    color: "#000000",
    opacity: 1,
    fillColor: "#e71708",
    fillOpacity: "0.2",
    zIndex: 1003,
  });
  const [searchData, setSearchData] = useState({
    project_id: "",
    category: "",
  });
  const [color, setColor] = useState({});
  const [clearnetwork, setClearnetwork] = useState(false);

  const [waterStyle, setwaterStyle] = useState({
    color: "#1201F9",
    opacity: 0.7,
    fillColor: "#B8EBF5",
    fillOpacity: "0.2",
    zIndex: 1001,
  });
  const [railStyle, setRailStyle] = useState({ color: "#747c8a", opacity: 2 });
  const [reserveStyle, setReserveStyle] = useState({
    color: "#40A609",
    opacity: 0.8,
    fillColor: "#95D672",
    fillOpacity: "0.2",
    zIndex: 1001,
  });

  const [waterLayerData, setWaterLayerData] = useState();
  const [railwayLayerData, setRailwayLayerData] = useState();
  const [reserverLayerData, setReserverLayerData] = useState();

  // const [transformerCheckedIds, setTransformerCheckedIds] = useState([]);
  const [subStationGeojson, setSubStationGeojson] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [subStatation, setSubStatation] = useState([]);
  const [baseLayer, setBaseLayer] = useState([]);

  const [findPlaceModal, setFindPlaceModal] = useState(false);
  const [placeGeoJson, setPlaceGeoJson] = useState(null);
  const [placeBoundaryGeoJson, setBoundaryPlaceGeoJson] = useState(null);

  useEffect(() => {
    if (placeGeoJson?.features[0]?.properties?.name) {
      const districtName = encodeURIComponent(
        placeGeoJson.features[0].properties.name
      );
      // console.log("Fetching boundary for:", districtName);

      fetch(
        `https://geoserver.fornaxenergytech.com/geoserver/Gis_Gujarat/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Gis_Gujarat:District&outputFormat=application/json&CQL_FILTER=district_n='${districtName}'`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => setBoundaryPlaceGeoJson(data))
        .catch((error) => console.error("Error fetching boundary:", error));
    }
  }, [placeGeoJson]);

  const handleClearNetwork = () => {
    setOrganization([]);
    setCircleData(null);
    setSubDivisionData(null);
    setDivisionData(null);
    setCircleGeojson(prevState => ({
      'ver': prevState.ver+1,
      'geojson': null
    }));
    setCompanyGeojson(null);
    setDivisionGeojson(null);
    setSubDivisionData(null);
    // setCircles([]);
    // setEHVLinegeojson(false);
    setSubDivisiongeojson(null);
    // setTraceBetween([]);
    setMarkers([]); // Set markers to an empty array
    // setVisibleElements([]);
    // setDatapoint([]);
    // setDataLine([]);
    // console.log("Clear All Network");
  };

  const handleMeasureChange = (mode) => {
    if (measureMode === mode) {
      setMeasureMode(null);
    } else {
      setMeasureMode(mode);
      setMeasurementVisible(true);
    }
  };

  const handleBaseLayerChange = (data) => {
    if (baseLayer == data) {
      setBaseLayer([]);
    } else {
      setBaseLayer([data]);
    }

    if (
      data == "Talukas" &&
      !baseLayer.includes("Talukas") &&
      selectedDistrictId == null
    ) {
      setIsTalukaVisible(false);
      setErrorMessage("Please Select District");
      setBaseLayer([]);
    } else {
      setErrorMessage("");
    }
  };

  const handleClearLineNetwork = () => {
    // setDatapoint([]);
    // setDataLine([]);
    setLineDataGeoJSON([]);
    // setEHVLinegeojson(null);
    // setObjectgeojson(null);
    setSubStationGeojson([]);
    setIsshowIds([]);
    // setEHVLinegeojson(false);
    // setSubDivisiongeojson(null);
    // setTraceBetween([]);
    setMarkers([]); // Set markers to an empty array

    setClearnetwork(!clearnetwork);
    // console.warn("handleClearLineNetwork");
  };

  const handleRefresh = () => {
    // setActiveBaseLayer('');
    setSelectedTaluka("");
    // setIsDistrictVisible(false);
    setIsTalukaVisible(false);
    setGeojsonTalukaData(null);
    setGeojsonData(null);
    setSelectedDistrictId(null);
  };

  useEffect(() => {
    // Update map center when center state changes
    if (mapRef.current) {
      mapRef.current.setView(center);
    }
  }, [center]);

  // useEffect(() => {
  //   Object.keys(visibleElements).map((key) => {
  //     const elements = document.getElementsByClassName(
  //       `leaflet-marker-icon ${key}`
  //     );
  //     for (let i = 0; i < elements.length; i++) {
  //       if (visibleElements[key]) {
  //         elements[i].classList.remove("d-none");
  //       } else {
  //         elements[i].classList.add("d-none");
  //       }
  //     }
  //   });
  // }, [visibleElements]);

  const calculateCenter = (coords) => {
    if (coords.length === 0) return [0, 0];

    let totalLat = 0;
    let totalLng = 0;
    coords.forEach((coord) => {
      totalLat += parseFloat(coord[1]);
      totalLng += parseFloat(coord[0]);
    });

    const avgLat = totalLat / coords.length;
    const avgLng = totalLng / coords.length;

    setCenter([avgLat, avgLng]);
    return [avgLat, avgLng];
  };

  var result = [];
  const buildNestedStructure = (items, parentId) => {
    items.features.map((item) => {
      if (item.properties.parent_id === parentId) {
        const children = buildNestedStructure(items, item.id);
        if (children.length) {
          item.children = children;
        }
        item.properties.color = "green";
        result.push(item);
      }
    });

    return result;
  };

  const traceToTopParent = (id, path = []) => {
    if (!id || !dataLine) {
      return path;
    }
    const feature = dataLine.features.find(
      (feature) => feature.properties.id === id
    );
    if (feature && feature.id) {
      feature.properties.color = "green";
      path.push(feature); // Add current feature to the path

      if (feature?.properties?.parent_id) {
        return traceToTopParent(feature.properties.parent_id, path); // Recursively trace to the parent
      }
    }
    return path;
  };

  const toggleMenu = () => {
    setFloatingActionButton(!floatingActionButton);
  };

  const toggleLeftSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleFornaxHomeClick = () => {
    const storedAuth = localStorage.getItem("_auth");
    const url = storedAuth
      ? process.env.REACT_APP_MAP_VIEW_URL + `/?q=${storedAuth}`
      : "https://map.fornaxenergytech.com/dashboard";
    window.open(url);
  };

  const handleLayerChange = (layerName) => {
    setActiveLayer(layerName);
  };
console.log(baseURL+"/map_icon/Transformer.svg");
  const pointTypesData = {
    Transformer: baseURL+"/map_icon/Transformer.svg",
    Fuse: baseURL+"/map_icon/Fuse.svg",
    Gentry: baseURL+"/map_icon/Gentry.svg",
    // "HT Pole": "StarSVG.svg",
    RMU: baseURL+"/map_icon/RMU.svg",
    "HT Route Point": baseURL+"/map_icon/ht_route_point.svg",
    "RMU with TC": baseURL+"/map_icon/rmu_with_tc.svg",
    Switch: baseURL+"/map_icon/Switch.svg",
    CTPT: baseURL+"/map_icon/CTPT.svg",
    "Existing Pole(Double Circuit)": baseURL+"/map_icon/existing_pole.svg",
    // Add mappings for other point types here
  };

  const createCustomIcon = (svgFileName) => {
    return L.icon({
      iconUrl: svgFileName, // Update with the actual path to your SVG files
      iconSize: [20, 20], // Adjust size as needed
      iconAnchor: [12, 12], // Adjust anchor as needed
    });
  };

  const pointToLayer = (feature, latlng) => {
    const pointTypeName = feature.properties.name;

    // Use the mapped icon or a default one
    if (pointTypeName && pointTypeName in pointTypesData) {
      // console.log("pointTypeName:", pointTypeName);
      return L.marker(latlng, {
        icon: createCustomIcon(pointTypesData[pointTypeName]),
      });
    } else {
      console.warn(`No icon mapping found for point type: ${pointTypeName}`);
      return L.marker(latlng); // Default marker
    }
  };

  useEffect(() => {
    // console.log("It is working");
    if (geojsonData && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(geojsonData);

      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [geojsonData]);

  useEffect(() => {
    if (placeGeoJson && mapRef.current) {
      const geoJsonLayer = L.geoJSON(placeGeoJson);
      const bounds = geoJsonLayer.getBounds();

      if (bounds.isValid()) {
        const currentZoom = mapRef.current.getBoundsZoom(bounds);
        const adjustedZoom = currentZoom - currentZoom * 0.4; // Reduce zoom by 20%

        mapRef.current.flyToBounds(bounds, { maxZoom: adjustedZoom });
      }
    }
  }, [placeGeoJson]);

  useEffect(() => {
    // console.log("It is working");
    if (geojsonTalukaData && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(geojsonTalukaData);

      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [geojsonTalukaData]);

  // console.log("THe data",setPointGeometry, )
  // console.log("THe data 1", measureMode)
  // console.log("THe data 2", setMeasureResult)
  // console.log("THe data 3", setDrawnLayers)

  const [selectedDraw, setSelectedDraw] = useState();
  const handleSelectedData = (name, geometry) => {
    setSelectedDraw({
      layerName: name,
      geometry: geometry,
    });

    // console.log(name,geometry);
  };

  //
  // const [layerVisibility, setLayerVisibility] = useState(false);
  const [circleData, setCircleData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [companyGeojson, setCompanyGeojson] = useState([]);
  const [subDivisiongeojson, setSubDivisiongeojson] = useState([]);
  // const[filterEHVJson,setFilterEHVJson] = useState();
  // const[eHVLinegeojson,setEHVLinegeojson] = useState();
  const [circleGeojson, setCircleGeojson] = useState({
    'ver': 0,
    'geojson': null
  });
  const [divisionGeojson, setDivisionGeojson] = useState();

  const [infraStructure, setInfraStructure] = useState([]);

  const [organizations, setOrganizations] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const [subDivisions, setSubDivisions] = useState([]);

  const [organization, setOrganization] = useState([]);

  const [searchId, setSearchId] = useState();

  // const [substationIds, setSubstationIds] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [subDivisionData, setSubDivisionData] = useState([]);
  const [lineData, setLineData] = useState(null);
  const [lineDataGeoJSON, setLineDataGeoJSON] = useState();

  useEffect(() => {
    // console.log("Invalid divisionData:", divisionData);

    if (
      !divisionData ||
      !Array.isArray(divisionData) ||
      divisionData.length === 0
    ) {
      setDivisionGeojson([]);
      console.warn("Invalid divisionData:", divisionData);
      return;
    }

    const geojsonFeatures2 = divisionData
      .filter((data) => data.geometry && data.geometry.type === "MultiPolygon")
      .map((data) => ({
        type: "Feature",
        properties: {
          id: data.id,
          name: data.properties?.organization_name || "Unknown",
          type: "SubDivision",
        },
        geometry: data.geometry,
      }));

    if (geojsonFeatures2.length === 0) {
      console.warn("No valid features in DivisionData.");
      return;
    }

    const geojson = {
      type: "FeatureCollection",
      features: geojsonFeatures2,
    };

    // console.log("geojson",geojson);
    setDivisionGeojson(geojson);
  }, [divisionData]);

  useEffect(() => {
    if (
      !subDivisionData ||
      !Array.isArray(subDivisionData) ||
      subDivisionData.length === 0
    ) {
      console.warn("Invalid subDivisionData:");
      return;
    }

    const geojsonFeatures2 = subDivisionData
      .filter((data) => data.geometry && data.geometry.type === "MultiPolygon")
      .map((data) => ({
        type: "Feature",
        properties: {
          id: data.id,
          name: data.properties?.organization_name || "Unknown",
          type: "SubDivision",
        },
        geometry: data.geometry,
      }));

    if (geojsonFeatures2.length === 0) {
      console.warn("No valid features in subDivisionData.");
      return;
    }

    const geojson = {
      type: "FeatureCollection",
      features: geojsonFeatures2,
    };

    // Prevent unnecessary state updates
    setSubDivisiongeojson((prevGeojson) => {
      if (JSON.stringify(prevGeojson) !== JSON.stringify(geojson)) {
        console.error("Setting new geojson:", geojson);
        return geojson;
      }
      return prevGeojson;
    });
  }, [subDivisionData]);

  const [isshowssIds, setIsshowIds] = useState([]);
  // const[lineprojectids, setLineprojectids] = useState([]);
//   function manageIdsArray(arr, newId) {
//     if (!arr.includes(newId)) { // Prevent duplicates
//         if (arr.length >= 3) {
//             arr.shift(); // Remove the oldest element (first one)
//         }
//         // arr.push(newId); // Add the new element at the end
//     }
//     return arr;
// }
const onHandleSSIds = (ssids) => {
  // console.log("updatedIds",ssids);

  // Avoid duplicates by checking if the id already exists in the array
  setIsshowIds((prevIds) => {
    // Only update if the id doesn't already exist and the length is not exceeding 2
    if (!prevIds.includes(ssids)) {
      // If the array has 2 items already, remove the first item and add the new ssid
      const updatedIds = prevIds.length >= 2 ? [...prevIds.slice(1), ssids] : [...prevIds, ssids];
      return updatedIds;
    }
    return prevIds; // Don't update if no changes are needed
  });
};

const [categorywisteFilter,setCategorywisteFilter] = useState([]);
useEffect(() => {
  const fetchAndFitGeoJSONData = async () => {
    if (isshowssIds.length === 0) {
      setLineDataGeoJSON(null);
      return;
    }

    setLoading(true);
    // const categorywisteFilter = [
    //   { substaionId: 177, category: ["URBAN", "IND","HTEX","JGY", "SST", "ADOM"] },
    //   // { substaionId: 160, category: ["URBAN", "ADOM"] }
    // ];
    // console.log('categorywisteFilter',categorywisteFilter);
    // Construct the CQL_FILTER dynamically
    const cqlFilters = categorywisteFilter
      .map(({ substaionId, category }) => {
    // console.log('categorywisteFilter',category,substaionId);

        const categoryFilter = category.map(cat => `'${cat}'`).join(",");
        return `substation_id = ${substaionId} AND category IN (${categoryFilter})`;
      })
      .join(" OR ");  // Combine each filter for different substaionId/category pairs with OR

    try {
      const updatedIds = Array.from(new Set(isshowssIds)); // Ensure no duplicates
      // console.log("updatedIds",updatedIds);

      const formattedIds = updatedIds.map((id) => `'${id}'`).join(",");
      const wfsUrl = `https://geoserver.fornaxenergytech.com/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&typeName=Gis_Gujarat:hv_network&outputFormat=application/json&CQL_FILTER=substation_id IN (${formattedIds}) AND ${cqlFilters}`;

      const { data } = await axios.get(wfsUrl);
      // console.log('Response Data ',wfsUrl)

      if (data?.features?.length > 0) {
        setLineDataGeoJSON(data);

        // Fit map bounds after updating state
        setTimeout(() => {
          if (mapRef.current) {
            const geoJsonLayer = L.geoJSON(data);
            const bounds = geoJsonLayer.getBounds();
            if (bounds.isValid()) {
              mapRef.current.fitBounds(bounds, { animate: true, duration: 3 });
            }
          }
        }, 100);
      } else {
        setLineDataGeoJSON(null);
      }
    } catch (error) {
      console.error("Error fetching GeoJSON data:", error);
      setLineDataGeoJSON(null);
    } finally {
      setLoading(false);
    }
  };

  fetchAndFitGeoJSONData();
}, [isshowssIds,categorywisteFilter]);

const onHandleLineCheck = (ssId) => {
  
  if (isshowssIds.includes(ssId)) {

    return true;
  }else{
    return false;
  }


}
const onHandleCategory = (data) => {
  // console.log("Check Category", data);

  setCategorywisteFilter((prev) => {
    // Check if the substationId already exists in the state
    const existingIndex = prev.findIndex(item => item.substaionId === data.substaionId);

    let updatedData;

    if (existingIndex !== -1) {
      // If found, update that entry
      updatedData = [...prev];
      updatedData[existingIndex] = data;
    } else {
      // If not found, add the new entry
      updatedData = [...prev, data];
    }

    // If the array length exceeds 3, remove the first entry (FIFO behavior)
    if (updatedData.length > 3) {
      updatedData.shift(); // Remove the first item
    }

    return updatedData;
  });
};



  const [filteredObject, setFilteredObject] = useState([]);
  const [selectedPointType, setSelectedPointType] = useState([]);

  const [subMenuSection, setSubMenuSection] = useState(null);

  useEffect(() => {
    // console.error("Point types", selectedPointType);
    // Ensure required dependencies are available before calling the function
    if (selectedPointType.length > 0) {
      fetchObjectData(categorywisteFilter, selectedPointType, setFilteredObject);
    }
  }, [categorywisteFilter, selectedPointType]);

  // const[objectgeojson,setObjectgeojson] = useState();

  const [gujaratHighwayData, setGujaratHighwayData] = useState(null);

  useEffect(() => {
    // console.log("It is working");
    if (companyGeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(companyGeojson);

      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds, {
          animate: true, // Enable animation
          duration: 1.5, // Duration of the animation in seconds
          easeLinearity: 0.25, // Adjust the ease effect (optional)
          maxZoom: 15, // Set a maximum zoom level (optional)
        });
      }
    }
  }, [companyGeojson]);

  useEffect(() => {
    if (circleGeojson.geojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(circleGeojson.geojson);

      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer with animation
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds, {
          animate: true, // Enable animation
          duration: 1.5, // Duration of the animation in seconds
          easeLinearity: 0.25, // Adjust the ease effect (optional)
          maxZoom: 15, // Set a maximum zoom level (optional)
        });
      }
    }
  }, [circleGeojson]);

  useEffect(() => {
    // console.log("It is working");
    if (divisionGeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(divisionGeojson);

      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds, {
          animate: true, // Enable animation
          duration: 1.5, // Duration of the animation in seconds
          easeLinearity: 0.25, // Adjust the ease effect (optional)
          maxZoom: 15, // Set a maximum zoom level (optional)
        });
      }
    }
  }, [divisionGeojson]);

  useEffect(() => {
    // console.log("It is working");
    if (subDivisiongeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(subDivisiongeojson);

      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds, {
          animate: true, // Enable animation
          duration: 1.5, // Duration of the animation in seconds
          easeLinearity: 0.25, // Adjust the ease effect (optional)
          maxZoom: 15, // Set a maximum zoom level (optional)
        });
      }
    }
  }, [subDivisiongeojson]);

  // const selectedPointTypes = ['Transformer', 'Fuse'];

  // useEffect(() => {
  // console.error("zoom:", zoom);

  //   if (zoom > 12) {
  //             setShowGeoJson(true);  // Show GeoJSON if zoom is greater than 15
  //           } else {
  //             setShowGeoJson(false); // Hide GeoJSON if zoom is 15 or below
  //           }
  // }, [zoom]);

  useEffect(() => {
    if (subMenuSection == "base-layer") {
      // console.error("Base-layer check");
      setBaseLayer((prevBaseLayer) =>
        prevBaseLayer.filter((layer) => layer !== "Talukas")
      );
    }
  }, [subMenuSection]);

  // Function to get the centroid of a polygon or multipolygon
  const getCentroid = (geometry) => {
    let latlngs = [];

    // Check if it's a MultiPolygon
    if (geometry.type === "MultiPolygon") {
      geometry.coordinates.forEach((polygon) => {
        polygon[0].forEach((coord) => {
          latlngs.push(L.latLng(coord[1], coord[0]));
        });
      });
    } else if (geometry.type === "Polygon") {
      geometry.coordinates[0].forEach((coord) => {
        latlngs.push(L.latLng(coord[1], coord[0]));
      });
    }

    const bounds = L.latLngBounds(latlngs);
    return bounds.getCenter();
  };

  // Function to handle each feature for labeling and events
  const onEachFeature = (feature, layer) => {
    // console.error("Processing feature");

    if (
      feature.geometry.type === "MultiPolygon" ||
      feature.geometry.type === "Polygon"
    ) {
      const centroid = getCentroid(feature.geometry);
      const labelText = feature.properties.name || "Unnamed Polygon";

      // Add label at the centroid after the layer is added to the map
      let labelMarker;
      if (labelText) {
        layer.on("add", function () {
          labelMarker = L.marker(centroid, {
            icon: L.divIcon({
              className: "polygon-label",
              html: labelText, // Use feature's name as label
              iconSize: [100, 40],
            }),
          }).addTo(layer._map); // Add label to the map
        });

        // Remove the label when the layer is removed
        layer.on("remove", function () {
          if (labelMarker) {
            layer._map.removeLayer(labelMarker); // Remove the label
          }
        });
      }
    }

    // Optional: handle click events on features
    layer.on({
      click: () => {
        layer.setStyle({ zIndex: 2000, fillColor: "yellow", color: "black" });
        layer.getElement().classList.add("custom-highlight"); // Add custom class for shadow effect
      },
    });
  };

  // UseEffect to handle layer lifecycle when circleGeojson changes
  useEffect(() => {
    if (!circleGeojson.geojson) return;

    // Create a GeoJSON layer for circleGeojson
    const geojsonLayer = L.geoJSON(circleGeojson.geojson, {
      style: circleStyle,
      onEachFeature: (feature, layer) => {
        // Get the label for the circleGeojson only
        const labelText = feature.properties.name || "No Type";

        // Add label when the layer is added
        let labelMarker;
        if (labelText) {
          layer.on("add", function () {
            labelMarker = L.marker(layer.getBounds().getCenter(), {
              icon: L.divIcon({
                className: "circle-label",
                html: labelText, // Use feature's name as label
                iconSize: [100, 40],
              }),
            }).addTo(layer._map); // Add label to the map
          });

          // Remove the label when the layer is removed
          layer.on("remove", function () {
            if (labelMarker) {
              layer._map.removeLayer(labelMarker); // Remove the label
            }
          });
        }

        // Highlight circle on click
        layer.on({
          click: () => {
            layer.setStyle({
              zIndex: 2000,
              fillColor: "yellow",
              color: "black",
            });
            // Add a custom CSS class for shadow effect
            layer.getElement().classList.add("custom-highlight");
          },
        });
      },
    });

    // Cleanup previous geojsonLayer when circleGeojson changes
    return () => {
      if (geojsonLayer) {
        geojsonLayer.clearLayers(); // Clear previous layer and labels
      }
    };
  }, [circleGeojson]); // Trigger effect when circleGeojson changes

  const [maploading, setMapLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleExportPDF = () => {
    exportMapAsPDF(mapRef, setMapLoading, setSuccess, lineData);
  };

//  console.log('substation',subStatation)
  // Convert Substation Data to GeoJSON
  useEffect(() => {
    if (subStatation.length > 0) {
      const geojson = {
        type: "FeatureCollection",
        features: subStatation.map((item) => ({
          type: "Feature",
          properties: { name: item.label, substation_id: item.ss_id },
          geometry: { type: "Point", coordinates: [item.lng, item.lat] },
        })),
      };
      setSubStationGeojson(geojson);
      // console.error("GeoJSON Data:", geojson);
    }
  }, [subStatation]);

  const getColorForSubstation = (substationId) => {
    // console.warn("Substation According to color", substationId)
    return color[substationId] || "#0252A8"; // Default color
  };

  // console.log("Reserve Network", reserverLayerData);
  const [flyname, setFlyname] = useState(null);

  useEffect(() => {
    if (!mapRef.current) return;

    const map = mapRef.current;

    if (selectedPointType.length > 0 && filteredObject?.length > 0) {
      const filtered = filteredObject.filter((item) =>
        selectedPointType.includes(item.properties.point_type)
      );

      // console.log(filtered);
      

      const geojsonFeatures2 = filtered
        .filter((data) => data.geometry?.type === "Point")
        .map((data) => ({
          type: "Feature",
          properties: {
            id: data.properties.id,
            name: data.properties?.point_type || "Unknown",
            type: data.properties?.point_type || "Unknown",
            feederId: data.properties?.project_id || "Unknown",
            category: data.properties?.category || "Unknown",
            projectName:data.properties?.project_name || "Unknown",
            location_name:data.properties?.location_name | "Unknown",
          },
          geometry: data.geometry,
        }));
        
      // console.log(geojsonFeatures2.filter(item => item.properties.id == '15463916'));
      

      if (geojsonFeatures2.length > 0) {
        const geojson = {
          type: "FeatureCollection",
          features: geojsonFeatures2,
        };
        // setObjectgeojson(geojson);

        const clusterGroupKey = `cluster-group-${selectedPointType
          .sort()
          .join("-")}`;
        let clusterGroup = null;

        // Check if the cluster group already exists
        map.eachLayer((layer) => {
          if (layer.options?.clusterGroupKey === clusterGroupKey) {
            clusterGroup = layer;
          }
        });

        // Create or clear the cluster group
        if (!clusterGroup) {
          clusterGroup = L.markerClusterGroup({ clusterGroupKey });
          map.addLayer(clusterGroup);
        } else {
          clusterGroup.clearLayers();
        }

        // Add markers to the cluster group
        const markers = geojson.features.map((feature) => {
          const { geometry, properties } = feature;
          const latLng = L.latLng(
            geometry.coordinates[1],
            geometry.coordinates[0]
          );
          const marker = pointToLayer
            ? pointToLayer(feature, latLng)
            : L.marker(latLng);

            marker.bindPopup(`
              <div style="
                padding: 12px; 
                background-color: #f9f9f9; 
                border-radius: 8px; 
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
                font-family: Arial, sans-serif; 
                min-width: 220px;
              ">
                <h5 style="margin: 0 0 8px; font-size: 16px; font-weight: 600; color: #00000;">
                  Location Type: <span style="color:#254336;">${properties.type}</span>
                </h5>
                
                <div style="font-size: 14px; color: #254336; margin-bottom: 4px;">
                  <strong style="color: #00000;">Location ID:</strong> ${properties.id}
                </div>
                
                <div style="font-size: 14px; color: #254336; margin-bottom: 4px;">
                  <strong style="color: #00000;">Location Name:</strong> ${properties.location_name || 'N/A'}
                </div>
                
                <div style="font-size: 14px; color:#254336; margin-bottom: 4px;">
                  <strong style="color: ##00000;">Feeder:</strong> ${properties.projectName} (${properties.feederId})
                </div>
                
                <div style="font-size: 14px; color: #254336;">
                  <strong style="color: #00000;">Category:</strong> ${properties.category}
                </div>
              </div>
            `);
            

          return marker;
        });

        clusterGroup.addLayers(markers);

        // Cleanup function
        return () => {
          map.eachLayer((layer) => {
            if (layer.options?.clusterGroupKey === clusterGroupKey) {
              map.removeLayer(layer);
            }
          });
        };
      } else {
        // setObjectgeojson([]);
      }
    } else {
      // setObjectgeojson([]);
      map.eachLayer((layer) => {
        if (layer.options?.clusterGroupKey) {
          map.removeLayer(layer);
        }
      });
    }
  }, [selectedPointType, filteredObject, mapRef, pointToLayer]);

  const [isAnimating, setIsAnimating] = useState(false);

  const handleRotateAndVibrate = () => {
    setIsAnimating(true); // Start animation

    setTimeout(() => {
      setIsAnimating(false); // Stop animation
    }, 1500); // Complete after 1 second
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const findNearestSubstation = async (latlng) => {
    const baseURL = process.env.REACT_APP_RMU_DATA_URL;
    if (!latlng || latlng.length !== 2) return;
    const [latitude, longitude] = latlng;
    try {
      const response = await fetch(`${baseURL}/findnearestSubstation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ latitude, longitude }),
      });

      if (!response.ok) {
        throw new Error(`API Error: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.substation) {
        setNearestSubstation(data.substation);
      } else {
        alert("No nearest substation found.");
        setNearestSubstation(null);
      }
    } catch (error) {
      console.error("Error fetching nearest substation:", error);
      setNearestSubstation(null);
    }
  };

  const handleMouseOverForFindSubstation = () => {
    setNearestSubstationhovered(true);
  };

  const handleMouseOutForFindSubstation = () => {
    setNearestSubstationhovered(false);
  };

  const findNearestLocation = async (latlng) => {
    const baseURL = process.env.REACT_APP_RMU_DATA_URL;
    if (!latlng || latlng.length !== 2) return;
    const [latitude, longitude] = latlng;
    try {
      const response = await fetch(`${baseURL}/findNearestLocation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ latitude, longitude }),
      });

      if (!response.ok) {
        throw new Error(`API Error: ${response.statusText}`);
      }

      const data = await response.json();
      if (data.nearestLocation) {
        setNearestLocation(data.nearestLocation);
      } else {
        alert("No nearest location found.");
        setNearestLocation(null);
      }
    } catch (error) {
      console.error("Error fetching nearest location:", error);
      setNearestLocation(null);
    }
  };

  const handleMouseOverForFindLocation = () => {
    setNearestLocationhovered(true);
  };

  const handleMouseOutForFindLocation = () => {
    setNearestLocationhovered(false);
  };

  const handleLatLngSelect = (coords) => {
    setSelectedLatLng(coords);
  };

  const updateStnIds = (stnIds) => {
    // console.log(stnIds);
  };

  const stnsSelectHandler = (stns) => {
    // console.log(stns);
    // setIsshowIds(stns.map(item => item.ss_id));
    setSubStatation(stns);
  };

//   function manageIdsArray(arr, newId) {
//     if (!arr.includes(newId)) {
//         if (arr.length >= 3) {
//             arr.shift(); // Remove the first (oldest) element
//         }
//         arr.push(newId); // Add the new element
//     }
//     return arr;
// }





  const handleCircleData = (circleData) => {
    // setCircleData(data);

    // console.error("circleData",circleData);
    if (!circleData || !Array.isArray(circleData) || circleData.length === 0) {
      setCircleGeojson(prevState => ({
        'ver': prevState.ver+1,
        'geojson': null
      }));
      // console.warn("Invalid circleData:", circleData);
      return;
    }

    const geojsonFeatures1 = circleData
      .filter((data) => data.geometry && data.geometry.type === "MultiPolygon")
      .map((data) => ({
        type: "Feature",
        properties: {
          id: data.id,
          name: data.properties?.organization_name || "Unknown",
          type: "Circle",
        },
        geometry: data.geometry,
      }));

    if (geojsonFeatures1.length === 0) {
      console.warn("No valid features in circleData.");
      return;
    }

    const geojson = {
      type: "FeatureCollection",
      features: geojsonFeatures1,
    };

    setCircleGeojson(prevState => ({
      'ver': prevState.ver+1,
      'geojson': geojson
    }));
  };

  const handleInfraStructure = (data) => {
    setInfraStructure(data);
  };

  const handleCompanyData = (companyData) => {
    // setCompanyData(data);
    if (
      !companyData ||
      !Array.isArray(companyData) ||
      companyData.length === 0
    ) {
      setCompanyGeojson([]);
      // console.warn("Invalid CompanyData:", companyData);
      return;
    }

    const geojsonFeatures2 = companyData
      .filter((data) => data.geometry && data.geometry.type === "MultiPolygon")
      .map((data) => ({
        type: "Feature",
        properties: {
          id: data.id,
          name: data.properties?.organization_name || "Unknown",
          type: "Company",
        },
        geometry: data.geometry,
      }));

    if (geojsonFeatures2.length === 0) {
      console.warn("No valid features in Company Data.");
      return;
    }

    const geojson = {
      type: "FeatureCollection",
      features: geojsonFeatures2,
    };

    setCompanyGeojson(geojson);
  };

  const handleSubdivision = (data) => {
    // if (!Array.isArray(subDivisionData) || subDivisionData.length === 0) {
    //   console.warn("Invalid or empty subDivisionData.");
    //   return;
    // }
  
    // const geojsonFeatures2 = subDivisionData
    //   .filter(
    //     (data) =>
    //       data &&
    //       data.geometry &&
    //       data.geometry.type === "MultiPolygon"
    //   )
    //   .map((data) => ({
    //     type: "Feature",
    //     properties: {
    //       id: data.id,
    //       name: data.properties?.organization_name || "Unknown",
    //       type: "SubDivision",
    //     },
    //     geometry: data.geometry,
    //   }));
  
    // if (geojsonFeatures2.length === 0) {
    //   console.warn("No valid features in subDivisionData.");
    //   return;
    // }
  
    // const geojson = {
    //   type: "FeatureCollection",
    //   features: geojsonFeatures2,
    // };
  
    // // Prevent unnecessary state updates
    // setSubDivisiongeojson((prevGeojson) => {
    //   if (!prevGeojson || JSON.stringify(prevGeojson) !== JSON.stringify(geojson)) {
    //     console.log("Updating geojson:", geojson);
    //     return geojson;
    //   }
    //   return prevGeojson;
    // });
    setDivisionData(data);
  };
  const [drawPointData,setDrawPointData] = useState([]);
  
  const handleDrawPointType = (id, value) => {
    setDrawPointData((prev) => {
      // Check if the id already exists
      const existingIndex = prev.findIndex(item => item.point_name === id);
  
      if (existingIndex !== -1) {
        // If exists, update value
        return prev.map((item, index) =>
          index === existingIndex ? { ...item, value: value } : item
        );
      } else {
        // If not, add new entry
        return [...prev, { point_name: id, value: value }];
      }
    });
    
  
    // console.log("handle Point",id, value);
  };
const [isDrawData,setIsDrawData] = useState(false);
const [drawGeoJSON,setDrawGeoJSON] = useState([]);
  const handleShowObject = (data) => {
setIsDrawData(!isDrawData);
  

  }
  useEffect(() => {
    if (isDrawData) {

      const fetchDrawObjects = async () => {

        try {
          const response = await axios.get(`${baseURL}/fetch-draw-objects`, {
            // params: { username: 1 }, // Correct way to pass query params
          });
  // console.log(response.data.geojson);
          setDrawGeoJSON(response.data.geojson);
        } catch (e) {
          console.error("Error While Fetching Draw Data", e);
          setDrawGeoJSON([]);
        }
      };
  
      fetchDrawObjects();
    }else{
      setDrawGeoJSON([]);
    }
  }, [isDrawData]);

  const getColor = (type) => {
    switch (type) {
      case "Solar Park":
        return "yellow"; // Red
      case "Wind Park":
        return "Green"; // Blue
      case "Switch Yard":
        return "Grey"; // Green
     
      default:
        return "#AF1D1D"; // Gray (Default)
    }
  };

  function getPointIcon(type) {
    switch (type) {
      case "Solar Panel":
        return L.icon({
          iconUrl: '/solar_panel.svg',  // Provide the correct path to the icon
          iconSize: [32, 32],                  // Adjust size as needed
          iconAnchor: [16, 32],                // Anchor position
          popupAnchor: [0, -32]                // Popup position
        });
      default:
        return L.icon({
          iconUrl: '/default_icon.svg', // Default icon path
          iconSize: [32, 32],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32]
        });
    }
  }
  
  
  // consoleli.log(color);
  return (
    <>
      {/* <PrintControl geojsonData={circleGeojson}/> */}
      <div className="map-view">

      {/* {eHVLinegeojson} */}
      <TopNavbar
        // sendDataToParent={handleDataFromChild}
        organizations={organizations}
        setOrganizations={setOrganizations}
        divisions={divisions}
        setDivisions={setDivisions}
        subDivisions={subDivisions}
        setSubDivisions={setSubDivisions}
        setSearchId={setSearchId}
        searchData={searchData}
        setSearchData={setSearchData}
      />
      <div className="app" style={{ position: "relative" }}>
        <div
          className={`sidebar leftsidebar-container ${
            sidebarVisible ? "visible" : "hidden"
          }`}
        >
          <LeftSidebar
            // sendDataToParent={handleDataFromChild}
            setMapLayer={handleLayerChange}
            drawGeoJSON={drawGeoJSON}
            updateCenter={calculateCenter}
            addMerkers={setMarkers}
            // setLayerVisibility={setLayerVisibility}
            handleBaseLayerChange={handleBaseLayerChange}
            handleShowObject={handleShowObject}
            baseLayer={baseLayer}
            errorMessage={errorMessage}
            isTalukaVisible={isTalukaVisible}
            handleRefresh={handleRefresh}
            searchData={searchData}
            setCircleData={handleCircleData}
            // setPointDataElements={setPointDataElements}
            setInfraStructure={handleInfraStructure}
            infraStructure={infraStructure}
            handleClearNetwork={handleClearNetwork}
            setDistrictStyle={setDistrictStyle}
            setTalukaStyle={setTalukaStyle}
            setCompanyStyle={setCompanyStyle}
            companyStyle={companyStyle}
            setCompanyData={handleCompanyData}
            stnIdsHandler={updateStnIds}
            handleDrawPointType={handleDrawPointType}
            // setSubstationIds={setSubstationIds}
            setHighwayStyle={setHighwayStyle}
            organization={organization}
            setOrganization={setOrganization}
            organizations={organizations}
            setOrganizations={setOrganizations}
            divisions={divisions}
            setDivisions={setDivisions}
            subDivisions={subDivisions}
            setSubDivisions={setSubDivisions}
            setDivisionData={handleSubdivision}
            setSubDivisionData={setSubDivisionData}
            setCircleStyle={setCircleStyle}
            setDivisionStyle={setDivisionStyle}
            setSubDivisionStyle={setSubDivisionStyle}
            circleStyle={circleStyle}
            divisionStyle={divisionStyle}
            subDivisionStyle={subDivisionStyle}
            talukaStyle={talukaStyle}
            districtStyle={districtStyle}
            highwayStyle={highwayStyle}
            measureDetail={measureDetail}
            selectedPointType={selectedPointType}
            setSelectedPointType={setSelectedPointType}
            subMenuSection={subMenuSection}
            setSubMenuSection={setSubMenuSection}
            handleClearLineNetwork={handleClearLineNetwork}
            clearnetwork={clearnetwork}
            handleAreaPrint={handleExportPDF}
            maploading={maploading}
            success={success}
            setGujaratHighwayData={setGujaratHighwayData}
            measureMode={measureMode}
            handleMeasureChange={handleMeasureChange}
            // setIsshowIds={setIsshowIds}
            isshowssIds={isshowssIds}
            waterStyle={waterStyle}
            setwaterStyle={setwaterStyle}
            setWaterLayerData={setWaterLayerData}
            setRailwayLayerData={setRailwayLayerData}
            setReserverLayerData={setReserverLayerData}
            reserveStyle={reserveStyle}
            setReserveStyle={setReserveStyle}
            railStyle={railStyle}
            setRailStyle={setRailStyle}
            setIsGujaratBoundary={setIsGujaratBoundary}
            isGujaratBoundary={isGujaratBoundary}
            onStnsSelect={stnsSelectHandler}
            setFlyname={setFlyname}
          />
        </div>
        <div className="main-content">
          <button className="toggle-switch" onClick={toggleLeftSidebar}>
            <img className="bars-icon" src="menu.png" />
          </button>
          <button onClick={handleFornaxHomeClick} className="home-switch">
            <img className="bars-icon" src="home.png" />
          </button>

          <button
            onClick={handleRotateAndVibrate} // Corrected reload function
            className="home-switch"
          >
            <img
              className={`icon-container ${isAnimating ? "animate" : ""}`}
              src="reload.svg"
              alt="Reload Icon"
            />
          </button>

          {measureMode == null && (
            <div className="multi-action">
              <button
                className={`action-button ${
                  measureMode != null ? "disabled" : ""
                } ${floatingActionButton ? "active" : ""}`}
                onClick={() => {
                  toggleMenu(false);
                }}
                style={{
                  cursor: measureMode != null ? "not-allowed" : "pointer",
                }}
                disabled={measureMode != null}
              >
                <img
                  src="plus-lg.svg"
                  className="bg-white rounded-circle"
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "translate(0.2px, -1.6px)",
                  }}
                />
              </button>
              <ul className={`actions ${floatingActionButton ? "open" : ""}`}>
                <li
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setshowModalOfLatLangString(true);
                    toggleMenu(false);
                  }}
                >
                  <img
                    title="Search Lat, Long"
                    src="search-lat-lang.svg"
                    className="bg-white rounded-circle"
                    style={{ width: "35px", height: "35px" }}
                    alt="Search Lat Lang"
                  />
                </li>

                <li
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setFindPlaceModal(true);
                    toggleMenu(false);
                  }}
                >
                  <img
                    title="Search Place"
                    src="findPlaceIcon.svg"
                    className="bg-white rounded-circle"
                    style={{ width: "35px", height: "35px" }}
                    alt="Search Place"
                  />
                </li>
                <li
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setshowModalOfLatLangSeprate(true);
                    toggleMenu(false);
                  }}
                >
                  <img
                    title="Search Seprate Lat, Long"
                    src="find_search-xy.svg"
                    className="bg-white rounded-circle"
                    style={{ width: "35px", height: "35px" }}
                    alt="Search saprate lat long"
                  />
                </li>

                <li
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => {
                    toggleMenu(false);

                    setIsPickingLatLng(true);
                  }}
                >
                  <img
                    title="Lat Long picker"
                    src="latlong_picker.svg"
                    className="bg-white rounded-circle"
                    style={{ width: "35px", height: "35px" }}
                    alt="lat long picker"
                  />
                </li>
                <li
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => {
                    toggleMenu(false);
                    setPickSubstationLoc((prev) => !prev);
                  }}
                >
                  <img
                    title="Find Nearest Substation"
                    src="HV-Network.svg"
                    alt="substaion picker"
                    className="bg-white rounded-circle"
                    style={{ width: "35px", height: "35px" }}
                  />
                </li>

                <li
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => {
                    toggleMenu(false);
                    setPickLocation((prev) => !prev);
                  }}
                >
                  <img
                    title="Find Nearest Object"
                    src="location_finder.svg"
                    className="bg-white rounded-circle"
                    style={{ width: "35px", height: "35px" }}
                    alt="Search Lacation"
                  />
                </li>
              </ul>
            </div>
          )}

          <MapContainer
            zoom={zoom}
            zoomControl={false}
            ref={mapRef}
            center={center}
            maxBounds={gujaratBounds}
            minZoom={8}
          >
            <ZoomTracker setZoom={setZoom} />
            {isGujaratBoundary && (
              <WMSTileLayer
                url="https://geoserver.fornaxenergytech.com/geoserver/wfs"
                layers={`Gis_Gujarat:District`}
                format="image/png"
                transparent={true}
                opacity={0.7}
                attribution="InfraStructure Layer"
                zIndex={10000}
              />
            )}
            {subStationGeojson && (
              <MarkerCluster
                data={subStationGeojson}
                flyname={flyname}
                isshowssIds={isshowssIds}
                onHandleSSIds={onHandleSSIds}
                color={color}
                setColor={setColor}
                onHandleLineCheck={onHandleLineCheck}
                onHandleCategory={onHandleCategory}
              />
            )}
            {gujaratHighwayData && (
              <GeoJSON
                key={JSON.stringify(gujaratHighwayData)}
                data={gujaratHighwayData}
                renderer={L.canvas()} // Use canvas for faster rendering
                style={highwayStyle}
                onEachFeature={(feature, layer) => {
                  if (feature.properties && feature.properties.name) {
                    layer.bindPopup(feature.properties.name);
                  }
                }}
              />
            )}
            {waterLayerData && (
              <GeoJSON
                key={JSON.stringify(waterLayerData)}
                data={waterLayerData}
                renderer={L.canvas()} // Use canvas for faster rendering
                style={waterStyle}
                onEachFeature={(feature, layer) => {
                  if (feature.properties && feature.properties.name) {
                    layer.bindPopup(feature.properties.name);
                  }
                }}
              />
            )}
            {reserverLayerData && (
              <GeoJSON
                key={JSON.stringify(reserverLayerData)}
                data={reserverLayerData}
                renderer={L.canvas()} // Use canvas for faster rendering
                style={reserveStyle}
                onEachFeature={(feature, layer) => {
                  if (feature.properties && feature.properties.name) {
                    layer.bindPopup(feature.properties.name);
                  }
                }}
              />
            )}
            {railwayLayerData && (
              <GeoJSON
                key={JSON.stringify(railwayLayerData)}
                data={railwayLayerData}
                renderer={L.canvas()} // Use canvas for faster rendering
                style={railStyle}
                onEachFeature={(feature, layer) => {
                  if (feature.properties && feature.properties.name) {
                    layer.bindPopup(feature.properties.name);
                  }
                }}
              />
            )}
            {loading && <Loader loading={loading} />}
            {lineDataGeoJSON && (
              <GeoJSON
                key={JSON.stringify(lineDataGeoJSON)} // Force re-render when data changes
                data={lineDataGeoJSON}
                renderer={L.canvas()} // Use canvas for faster rendering
                // style={geoJSONStyle} // Use memoized style function
                style={(feature) => {
                  const substationId = feature.properties.substation_id;
                  // console.log(getColorForSubstation(substationId));
                  return {
                    color: getColorForSubstation(substationId),
                    weight: 2,
                    opacity: 0.9,
                  };
                }}
              />
            )}
            {/* <CustomComponent/> */}
            {measurementVisible && (
              <MapEvents
                // setPointGeometry={setPointGeometry}
                measureMode={measureMode}
                // setMeasureResult={setMeasureResult}
                // setDrawnLayers={setDrawnLayers}
                setSelectedData={setSelectedData}
                // measureDetail={measureDetail}
                setMeasureDetail={setMeasureDetail}
                drawPointData={drawPointData}
              />
            )}
            {circleGeojson.geojson != null && (
              <GeoJSON
                key={circleGeojson.ver} 
                data={circleGeojson.geojson}
                style={circleStyle}
                onEachFeature={onEachFeature}
              />
            )}
            {companyGeojson && (
              <GeoJSON
                key={JSON.stringify(companyGeojson)}
                data={companyGeojson}
                style={companyStyle}
                onEachFeature={onEachFeature}
              />
            )}
            {divisionGeojson && (
              <GeoJSON
                key={JSON.stringify(divisionGeojson)}
                data={divisionGeojson}
                style={divisionStyle}
                onEachFeature={onEachFeature}
              />
            )}
            {subDivisiongeojson && subDivisiongeojson?.features?.length > 0 && (
              <GeoJSON
                key={JSON.stringify(subDivisiongeojson)}
                data={subDivisiongeojson}
                style={subDivisionStyle}
                onEachFeature={onEachFeature}
              />
            )}

            {drawGeoJSON && (
              <GeoJSON
              key={JSON.stringify(drawGeoJSON)}
              data={drawGeoJSON}
              // pointToLayer={(feature, latlng) =>
              //   L.marker(latlng, { icon: getPointIcon(feature.properties.type) })
              // } 
              style={(feature) => ({
                color: getColor(feature.properties.type), // Border color
                fillColor: getColor(feature.properties.type), // Background color
                weight: 2,
                fillOpacity: 0.6, // Adjust opacity as needed
              })}
              onEachFeature={(feature, layer) => {
                const properties = feature.properties;
                // console.log('feature check',feature)
          
                layer.bindPopup(`
                  <div style="
                    padding: 12px; 
                    background-color: #f9f9f9; 
                    border-radius: 8px; 
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
                    font-family: Arial, sans-serif; 
                    min-width: 220px;
                  ">
                    <h5 style="margin: 0 0 8px; font-size: 16px; font-weight: 600; color: #000;">
                      Object Type: <span style="color:#254336;">${properties.type}</span>
                    </h5>
                    
                    <div style="font-size: 14px; color: #254336; margin-bottom: 4px;">
                      <strong style="color: #000;">Object ID:</strong> ${properties.id}
                    </div>
                    
                    <div style="font-size: 14px; color: #254336; margin-bottom: 4px;">
                      <strong style="color: #000;">Object Name:</strong> ${properties.name || 'N/A'}
                    </div>
                    
                    <div style="font-size: 14px; color:#254336; margin-bottom: 4px;">
                      <strong style="color: #000;">Document:</strong> ${properties.document_url}
                    </div>
                    
                 
                  </div>
                `);
              }}
            />
       
            )}
            {/* {getLatLong.length > 0 && (


)} */}
            {placeGeoJson && (
              <GeoJSON
                key={JSON.stringify(placeGeoJson)} // Force re-render when data changes
                data={placeGeoJson}
                pointToLayer={(feature, latlng) =>
                  L.marker(latlng, { icon: PointIcon })
                }
                onEachFeature={(feature, layer) => {
                  if (feature.properties && feature.properties.name) {
                    layer.bindTooltip(feature.properties.name, {
                      permanent: true,
                      direction: "top",
                      className: "custom-tooltip",
                      offset: [0, -28], // Moves label higher
                    });
                  }
                }}
              />
            )}
            {placeBoundaryGeoJson && (
              <GeoJSON
                key={JSON.stringify(placeBoundaryGeoJson)} // Force re-render when data changes
                data={placeBoundaryGeoJson}
                style={() => ({
                  color: "red",
                  weight: 3,
                  dashArray: "6, 6", // Dashed line
                  fillColor: "lightblue",
                  fillOpacity: 0.2,
                })}
              />
            )}
            {geojsonData && isMapVisble && (
              <GeoJSON
                // key={geojsonData.id}
                data={geojsonData}
                renderer={L.canvas()} // Use canvas for faster rendering
                style={districtStyle}
                onEachFeature={onEachFeature}
              />
            )}
            {geojsonTalukaData && isMapVisble && (
              <GeoJSON
                // key={JSON.stringify(geojsonTalukaData)}
                data={geojsonTalukaData}
                renderer={L.canvas()} // Use canvas for faster renderingLathi, Gujarat 365430bhargain
                style={talukaStyle}
                onEachFeature={onEachFeature}
              />
            )}
            <div className="border bg-white">
              <LayersControl className="bg-white">
                <ZoomControl position="bottomright" />
                <BaseLayer checked={activeLayer === "empty"} name="Empty">
                  <TileLayer
                    url="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                    maxZoom={30}
                    attribution=""
                  />
                </BaseLayer>

                <BaseLayer
                  checked={activeLayer === "OpenStreetMap"}
                  name="OpenStreetMap"
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    maxZoom={30}
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                </BaseLayer>

                <BaseLayer
                  checked={activeLayer === "Satellite"}
                  name="Satellite"
                >
                  <TileLayer
                    url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                    maxZoom={30}
                    subdomains={["mt1", "mt2", "mt3"]}
                  />
                </BaseLayer>

                {/* <BaseLayer checked={activeLayer === "BingMaps"} name="Bing Maps">
                <BingLayer
                  bingkey="AuhiCJHlGzhg93IqUH_oCpl_-ZUrIE6SPftlyGYUvr9Amx5nzA-WqGcPquyFZl4L"
                  type="AerialWithLabels"
                  maxNativeZoom={19}
                />
              </BaseLayer> */}
              </LayersControl>
            </div>{" "}
            {/* {markers && markers.map((marker) => (
              <div key={marker.value}>
                <Marker position={[marker.lng, marker.lat]} icon={customIcon}>
                  <Tooltip
                    className="custom-tooltip"
                    direction="bottom"
                    permanent
                  >
                    {marker.label}
                  </Tooltip>
                </Marker>
                <GeoJSON
                  data={JSON.parse(marker.feature)}
                  onEachFeature={onEachGeomDataFeature}
                />
              </div>
            ))} */}
            <SetViewToBounds markers={markers} />
            {/* 
            {circles && circles.map((circle, idx) => (
              <Circle
                key={idx}
                center={circle.latlng}
                radius={circle.radius}
                color={circle.color}
                fillColor={circle.fillColor}
                fillOpacity={circle.fillOpacity}
              />
            ))}
            {traceBetween && traceBetween.map((point, idx) => (
              <Circle
                key={idx}
                center={[
                  point.geometry.coordinates[1],
                  point.geometry.coordinates[0],
                ]}
                radius="10"
                color="red"
                fillOpacity="0"
              />
            ))} */}
            {/* {isMeasuring && <Marker position={measuringPoint}></Marker>} */}
            {/* {isMeasuring && cursorPosition && (
              <>
                <Polyline
                  positions={[measuringPoint, cursorPosition]}
                  color="black"
                />
                {/* <Marker position={midpoint} icon={distanceText}></Marker> */}
            {/* </> */}
            {/* )} */}
            {/* {points.length > 1 && (
              <>
                <Polyline positions={points} color="black">
                  {points.map((point, index) => {
                    if (index === 0) return null;
                    return (
                      <Tooltip
                        key={index}
                        direction="center"
                        offset={[0, 0]}
                        opacity={1}
                        permanent
                      >
                        <span>
                          Total Distance: {totalDistance.toFixed(2)} km
                        </span>
                        <br />
                      </Tooltip>
                    );
                  })}
                </Polyline>


              </>
            )} */}
            {/* {isMeasuring && <MyComponent />} */}
            {/* {findPlaceModal && ( */}
            <FindPlace
              findPlaceModal={findPlaceModal}
              setPlaceGeoJson={setPlaceGeoJson}
              setFindPlaceModal={setFindPlaceModal}
            />
            {/* )} */}
            <LatLngPicker
              isPickingLatLng={isPickingLatLng}
              setIsPickingLatLng={setIsPickingLatLng}
              handleLatLngSelect={handleLatLngSelect}
            />
            <SearchLatLng
              isVisible={showModalOfLatLangSeprate}
              setIsVisible={setshowModalOfLatLangSeprate}
            />
            <SearchLatLangString
              showModalOfLatLangString={showModalOfLatLangString}
              setshowModalOfLatLangString={setshowModalOfLatLangString}
            />
            {pickLocation && (
              <LocationSelector
                pickLocation={pickLocation}
                setNearestLocation={setNearestLocation}
                setPickLocation={setPickLocation}
                onLocationSelect={(latlng) => findNearestLocation(latlng)}
              />
            )}
            {nearestLocation && (
              <Marker
                ref={markerRef}
                position={[nearestLocation.latitude, nearestLocation.longitude]}
                icon={customIcon1}
                eventHandlers={{
                  mouseover: handleMouseOverForFindLocation,
                  mouseout: handleMouseOutForFindLocation,
                }}
              />
            )}
            {nearestLocationhovered && nearestLocation && markerRef.current && (
              <div
                className="position-absolute bg-white p-2 rounded shadow"
                style={{
                  top: `${
                    markerRef.current._map.latLngToContainerPoint([
                      parseFloat(nearestLocation.latitude),
                      parseFloat(nearestLocation.longitude),
                    ]).y - 40
                  }px`,
                  left: `${
                    markerRef.current._map.latLngToContainerPoint([
                      parseFloat(nearestLocation.latitude),
                      parseFloat(nearestLocation.longitude),
                    ]).x - 16
                  }px`,
                  pointerEvents: "none",
                  zIndex: 1000,
                }}
              >
                <h6 className="mb-1 fw-bold text-left">
                  Type: {nearestLocation.point_type || "Unknown"}
                </h6>

                <p className="mb-1 text-left">
                  <strong>Location Id</strong> {nearestLocation?.id || "N/A"}
                </p>
                <p className="mb-1 text-left">
                  <strong>Project Id</strong>{" "}
                  {nearestLocation?.project_id || "N/A"}
                </p>

                <p className="mb-1 text-left">
                  <strong>Device Type</strong>{" "}
                  {nearestLocation?.point_type || "N/A"}
                </p>

                <p className="mb-1 text-left">
                  <strong>Location Name:</strong>{" "}
                  {nearestLocation.point_props?.location_name || "N/A"}
                </p>
                <p className="mb-1 text-left">
                  <strong>Approx Distance:</strong>{" "}
                  {parseFloat(nearestLocation.distanceMeters).toFixed(2)} meters
                </p>
              </div>
            )}
            {pickSubstationLoc && (
              <SubstationSelector
                pickSubstationLoc={pickSubstationLoc}
                setNearestSubstation={setNearestSubstation}
                setPickSubstationLoc={setPickSubstationLoc}
                onLocationSelect={(latlng) => {
                  findNearestSubstation(latlng);
                }}
              />
            )}
            {nearestSubstation && (
              <Marker
                ref={markerRef}
                position={[nearestSubstation.lat, nearestSubstation.lng]}
                icon={customIcon}
                eventHandlers={{
                  mouseover: handleMouseOverForFindSubstation,
                  mouseout: handleMouseOutForFindSubstation,
                }}
              />
            )}
            {nearestSubstationhovered &&
              nearestSubstation &&
              markerRef.current && (
                <div
                  className="position-absolute bg-white p-2 rounded shadow"
                  style={{
                    top: `${
                      markerRef.current._map.latLngToContainerPoint([
                        parseFloat(nearestSubstation.lat),
                        parseFloat(nearestSubstation.lng),
                      ]).y - 40
                    }px`,
                    left: `${
                      markerRef.current._map.latLngToContainerPoint([
                        parseFloat(nearestSubstation.lat),
                        parseFloat(nearestSubstation.lng),
                      ]).x - 16
                    }px`,
                    pointerEvents: "none",
                    zIndex: 1000,
                  }}
                >
                  <h6 className="mb-1 fw-bold text-left">
                    Name: {nearestSubstation.ss_name || "Unknown Substation"}
                  </h6>

                  <p className="mb-1 text-left">
                    <strong>Voltage:</strong> {nearestSubstation.ss_voltage} kV
                  </p>
                  <p className="mb-1 text-left">
                    <strong>Substation ID:</strong> {nearestSubstation.ss_id}
                  </p>
                  <p className="mb-0 text-left">
                    <strong>Distance From Point:</strong>{" "}
                    {parseFloat(nearestSubstation.distance).toFixed(2)} km
                  </p>
                </div>
              )}
          </MapContainer>
        </div>

        <div className="sidebar right-sidebar-container">
          {subMenuSection == "base-layer" || subMenuSection == "objects" ? (
            <>
              {/* <RightSideBaseLayer
           activeBaseLayer={activeBaseLayer}
           selectedTaluka={selectedTaluka}
           isDistrictVisble={isDistrictVisble}
           isTalukaVisible={isTalukaVisible}
           setGeojsonData={setGeojsonData}
           setGeojsonTalukaData={setGeojsonTalukaData}
           setVillages={setVillages}
           /> */}

              <RightSideDrawBar
                // setErrorMessage={setErrorMessage}
                baseLayer={baseLayer}
                // activeBaseLayer={activeBaseLayer}
                selectedTaluka={selectedTaluka}
                // isDistrictVisble={isDistrictVisble}
                // isTalukaVisible={isTalukaVisible}
                setGeojsonData={setGeojsonData}
                setGeojsonTalukaData={setGeojsonTalukaData}
                // setVillages={setVillages}
                // setMeasureMode={setMeasureMode}
                measureMode={measureMode}
                handleMeasureChange={handleMeasureChange}
                // setIsDistrictVisible={setIsDistrictVisible}
                // selectedData={selectedData}
                // measureResult={measureResult}
                setIsTalukaVisible={setIsTalukaVisible}
                setSelectedDistrictId={setSelectedDistrictId}
                selectedDistrictId={selectedDistrictId}
                // subMenuSection={subMenuSection}
                measureDetail={measureDetail}
                // setIsTalukaVisible={setIsTalukaVisible}
              />
            </>
          ) : (
            ""
          )}

          {/* {
          subMenuSection == "organization"?  (
            <RightSidebar
              selectedFeature={featureProperties}
              setOpenedMenuOption={openedRightMenuOption}
              sendTracingData={handleTracing}
              networkDetails={networkDetails}
              getidInRighSidebar={getidInRighSidebar}
   
            />
          ) : (
            ""
          )} */}

          {/*  */}
        </div>
      </div>
      </div>
    </>
  );
};

const SetViewToBounds = ({ markers }) => {
  const map = useMap(); // This hook provides the map instance

  useEffect(() => {
    if (markers.length > 0) {
      const bounds = L.latLngBounds(
        markers.map((marker) => L.latLng(marker.lng, marker.lat))
      );
      map.fitBounds(bounds);
    }
  }, [markers, map]); // map is a stable object and does not cause re-renders

  return null; // This component does not render anything
};

export default MapView;
