import React from 'react';
import { useMap, Marker, Popup } from "react-leaflet";
import { useState, useEffect } from "react";
import L from "leaflet";

const customIcon = new L.Icon({
  iconUrl: "/dark-location-marker.svg",
  iconSize: [30, 30],
});

const LocationSelector = ({
  pickLocation,
  setPickLocation,
  onLocationSelect,
  setNearestLocation,
}) => {
  const map = useMap();
  const [selectedLatLng, setSelectedLatLng] = useState(null);

  useEffect(() => {
    if (pickLocation) {
      const handleMapClick = (e) => {
        const { lat, lng } = e.latlng;

        setSelectedLatLng([lat, lng]);
        onLocationSelect([lat, lng]);
      };

      map.on("click", handleMapClick);

      return () => {
        map.off("click", handleMapClick);
      };
    }
  }, [pickLocation, map, onLocationSelect]);

  const handleCancel = () => {
    setPickLocation(false);
    setSelectedLatLng(null);
    setNearestLocation(null);
  };

  return (
    <>
      {pickLocation && (
        <div style={instructionStyle} onClick={(e) => e.stopPropagation()}>
          <span>Click on the map to select a location.</span>
          <button onClick={handleCancel} style={cancelButtonStyle}>
            Cancel
          </button>
        </div>
      )}

      {selectedLatLng && (
        <Marker position={selectedLatLng} icon={customIcon}>
          <Popup>
            Selected Location: {selectedLatLng[0]}, {selectedLatLng[1]}
          </Popup>
        </Marker>
      )}
    </>
  );
};

const instructionStyle = {
  position: "absolute",
  top: "10px",
  left: "50%",
  transform: "translateX(-50%)",
  background: "rgba(0, 0, 0, 0.7)",
  color: "white",
  padding: "8px 12px",
  borderRadius: "5px",
  fontSize: "14px",
  zIndex: 9999,
  display: "flex",
  alignItems: "center",
  pointerEvents: "auto",
};

const cancelButtonStyle = {
  marginLeft: "10px",
  padding: "5px 10px",
  backgroundColor: "#f44336",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  pointerEvents: "auto",
};

export default LocationSelector;
