import React, { useEffect, useState } from "react";
import { useMap, Marker, Popup } from "react-leaflet";
import L from "leaflet";

const customIcon = L.icon({
  iconUrl: "/dark-location-marker.svg",
  iconSize: [30, 30],
  iconAnchor: [15, 30],
  popupAnchor: [0, -30],
});

const SearchLatLangString = ({
  showModalOfLatLangString,
  setshowModalOfLatLangString,
}) => {
  const [latLng, setLatLng] = useState("");
  const [zoom] = useState(18);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [substationMarker, setSubstationMarker] = useState(null);
  const [showToolbox, setShowToolbox] = useState(false);
  const [toolboxPosition, setToolboxPosition] = useState({ top: 0, left: 0 });
  const [nearestSubstation, setNearestSubstation] = useState(null);
  const map = useMap();
  const [showpointDetailBoxSubstation, setShowPointDetailBoxSubstation] =
    useState(false);
  const [nearestLocation, setNearestLocation] = useState(null);
  const [locationMarker, setLocationMarker] = useState(null);
  const [showpointDetailBoxLocation, setShowPointDetailBoxLocation] =
    useState(false);
  const [loadingSubstation, setLoadingSubstation] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);

  const [copied, setCopied] = useState(false);

  const latLngRegex = /^-?\d{1,3}\.\d+\s*,\s*-?\d{1,3}\.\d+$/;

  useEffect(() => {
    if (showModalOfLatLangString) {
      setLatLng("");
      setNearestSubstation(null);
      setNearestLocation(null);
    }
  }, [showModalOfLatLangString]);

  const handleSearch = async () => {
    let trimmedInput = latLng.trim();

    if (!latLngRegex.test(trimmedInput)) {
      alert("Invalid format! Please Enter Valid Format");
      return;
    }
    const [latStr, lngStr] = trimmedInput.split(",").map((val) => val.trim());
    const latNum = parseFloat(latStr);
    const lngNum = parseFloat(lngStr);

    if (isNaN(latNum) || isNaN(lngNum)) {
      alert("Invalid numbers! Enter valid latitude and longitude.");
      return;
    }
    setMarkerPosition([latNum, lngNum]);
    setShowToolbox(false);
    map.flyTo([latNum, lngNum], zoom, {
      animate: true,
      duration: 3,
      easeLinearity: 0.25,
    });

    setTimeout(() => {
      setshowModalOfLatLangString(false);
    }, 100);
  };

  const findNearestSubstation = async (latlng) => {
    const baseURL = process.env.REACT_APP_RMU_DATA_URL;
    if (!latlng || latlng.length !== 2) return;
    const [latitude, longitude] = latlng;
    setLoadingSubstation(true);

    try {
      const response = await fetch(`${baseURL}/findnearestSubstation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ latitude, longitude }),
      });

      if (!response.ok) {
        throw new Error(`API Error: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.substation) {
        setNearestSubstation(data.substation);
      } else {
        setNearestSubstation({
          ss_name: "N/A",
          ss_voltage: "N/A",
          ss_id: "N/A",
          distance: "N/A",
        });
      }
    } catch (error) {
      setNearestSubstation({
        ss_name: "N/A",
        ss_voltage: "N/A",
        ss_id: "N/A",
        distance: "N/A",
      });
    } finally {
      setLoadingSubstation(false);
    }
  };

  const findNearestLocation = async (latlng) => {
    const baseURL = process.env.REACT_APP_RMU_DATA_URL;
    if (!latlng || latlng.length !== 2) return;
    setLoadingLocation(true);

    const [latitude, longitude] = latlng;

    try {
      const response = await fetch(`${baseURL}/findNearestLocation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ latitude, longitude }),
      });

      if (!response.ok) {
        throw new Error(`API Error: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.nearestLocation) {
        setNearestLocation(data.nearestLocation);
      } else {
        setNearestLocation({ id: "N/A" });
      }
    } catch (error) {
      console.error("Error fetching nearest location:", error);
      setNearestLocation({ id: "N/A" });
    } finally {
      setLoadingLocation(false);
    }
  };

  const showSubstation = () => {
    if (!nearestSubstation) {
      alert("No substation data available!");
      return;
    }
    const lat = parseFloat(
      nearestSubstation.lat || nearestSubstation.ss_latitude
    );
    const lng = parseFloat(
      nearestSubstation.lng || nearestSubstation.ss_longitude
    );

    if (isNaN(lat) || isNaN(lng)) {
      alert("⚠️ Invalid substation coordinates!");
      return;
    }
    map.flyTo([lat, lng], zoom, {
      animate: true,
      duration: 3,
      easeLinearity: 0.25,
    });
    setSubstationMarker([lat, lng]);
    setShowToolbox(false);
  };

  const showLocation = () => {
    if (!nearestLocation) {
      alert("No substation data available!");
      return;
    }
    const lat = parseFloat(nearestLocation.lat || nearestLocation.latitude);
    const lng = parseFloat(nearestLocation.lng || nearestLocation.longitude);

    if (isNaN(lat) || isNaN(lng)) {
      alert("⚠️ Invalid substation coordinates!");
      return;
    }
    map.flyTo([lat, lng], zoom, {
      animate: true,
      duration: 3,
      easeLinearity: 0.25,
    });
    setLocationMarker([lat, lng]);
    setShowToolbox(false);
  };

  const openToolboxAtMarker = (e) => {
    const { lat, lng } = e.latlng;
    const point = map.latLngToContainerPoint([lat, lng]);

    setToolboxPosition({ top: point.y, left: point.x });
    setShowToolbox(true);
  };

  const closeToolbox = () => {
    setShowToolbox(false);
  };

  const openSubstationMarker = () => {
    setShowPointDetailBoxSubstation(true);
  };

  const closeSubstationMarker = () => {
    setShowPointDetailBoxSubstation(false);
  };

  const openLocationMarker = () => {
    setShowPointDetailBoxLocation(true);
  };

  const closeLocationMarker = () => {
    setShowPointDetailBoxLocation(false);
  };

  const handleCopy = () => {
    if (nearestLocation.id) {
      navigator.clipboard.writeText(nearestLocation.id);
      setCopied(true);

      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <>
      {showModalOfLatLangString && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "65px",
            zIndex: 1000,
            background: "white",
            borderRadius: "10px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            padding: "10px",
            width: "230px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ddd",
              paddingBottom: "5px",
              marginBottom: "8px",
            }}
          >
            <h6 style={{ margin: 0, fontSize: "14px",color:'#254336', fontWeight: "600" }}>
              Search by Lat & Lng
            </h6>
            <button
              type="button"
              onClick={() => setshowModalOfLatLangString(false)}
              style={{
                background: "none",
                border: "none",
                fontSize: "16px",
                cursor: "pointer",
                color: "#555",
              }}
            >
              ✖
            </button>
          </div>

          <input
            type="text"
            placeholder="e.g., -22.9228, 126.7773"
            value={latLng}
            onChange={(e) => setLatLng(e.target.value)}
            style={{
              width: "100%",
              padding: "6px",
              fontSize: "12px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginBottom: "8px",
            }}
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={handleSearch}
              style={{
                background: "#6b8a7a",
                color: "white",
                padding: "6px 15px",
                border: "none",
                borderRadius: "20px",
                fontSize: "12px",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              Search
            </button>
            <button
              onClick={() => setshowModalOfLatLangString(false)}
              style={{
                background: "white",
                color: "#254336",
                padding: "6px 15px",
                border: "1px solid #254336",
                borderRadius: "20px",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {markerPosition && (
        <Marker
          icon={customIcon}
          position={markerPosition}
          eventHandlers={{ click: openToolboxAtMarker }}
        />
      )}

      {showToolbox && markerPosition && (
        <div
          style={{
            position: "absolute",
            top: 17,
            left: 79,
            // top: toolboxPosition.top + 30,
            // left: toolboxPosition.left + 10,

            background: "#fff",
            padding: "15px",
            borderRadius: "8px",
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)",
            zIndex: 1000,
            maxWidth: "360px",
            textAlign: "left",
            fontSize: "14px",
            border: "1px solid #ddd",
          }}
        >
          <button
            onClick={closeToolbox}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              fontSize: "16px",
              cursor: "pointer",
              color: "#888",
            }}
          >
            ✖
          </button>

          <div style={{ marginBottom: "10px" }}>
            <p
              style={{ marginBottom: "5px", fontWeight: "600", color: "#333" }}
            >
              <i
                className="bi bi-geo-alt"
                style={{ color: "#007bff", marginRight: "5px" }}
              ></i>
              Selected Coordinates
            </p>
            <div
              style={{
                padding: "8px",
                background: "#f8f9fa",
                borderRadius: "5px",
                border: "1px solid #ddd",
              }}
            >
              <p className="mb-1">
                <strong>Latitude:</strong> {markerPosition[0]}
              </p>
              <p className="mb-0">
                <strong>Longitude:</strong> {markerPosition[1]}
              </p>
            </div>
          </div>

          <p style={{ fontWeight: "600", color: "#333", marginBottom: "8px" }}>
            <i
              className="bi bi-search"
              style={{ color: "#28a745", marginRight: "5px" }}
            ></i>
            Find Nearest :
          </p>
          <div className="d-flex flex-wrap gap-2 justify-content-start">
            <button
              onClick={() => findNearestSubstation(markerPosition)}
              className="btn-custom btn-sm "
              disabled={loadingSubstation}
            >
              {loadingSubstation ? (
                <>
                  <span className="spinner-border spinner-border-sm"></span>{" "}
                  Finding...
                </>
              ) : (
                "Substation"
              )}
            </button>

            <button
              onClick={() => findNearestLocation(markerPosition)}
              className="btn-custom btn-sm "
              disabled={loadingLocation}
            >
              {loadingLocation ? (
                <>
                  <span className="spinner-border spinner-border-sm"></span>{" "}
                  Finding...
                </>
              ) : (
                "Location"
              )}
            </button>
          </div>

          {nearestSubstation && (
            <div
              className="mt-3 p-2"
              style={{
                background: "#f0fcfc",
                borderRadius: "5px",
                border: "1px solid rgb(176, 192, 206)",
              }}
            >
              <p className="mb-1">
                <strong>Substation:</strong>{" "}
                {nearestSubstation.ss_name || "N/A"}
              </p>

              {nearestSubstation.ss_name !== "N/A" && (
                <button
                  onClick={showSubstation}
                  className="btn-custom btn-sm w-100"
                  style={{ fontSize: "12px" }}
                >
                  Show on Map
                </button>
              )}
            </div>
          )}

          {nearestLocation && (
            <div
              className="mt-2 p-2"
              style={{
                background: "#f0fcfc",
                borderRadius: "5px",
                border: "1px solid rgb(176, 192, 206)",
              }}
            >
              <p className="mb-1">
                <strong> Location ID:</strong> {nearestLocation.id || "N/A"}
                {nearestLocation.id && nearestLocation.id !== "N/A" && (
                  <span
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    title="Copy ID"
                    onClick={handleCopy}
                  >
                    {copied ? "✅" : "📋"}
                  </span>
                )}
              </p>

              {nearestLocation.id !== "N/A" && (
                <button
                  onClick={showLocation}
                  className="btn-custom btn-sm w-100"
                >
                  Show on Map
                </button>
              )}
            </div>
          )}
        </div>
      )}

      {substationMarker && (
        <Marker
          position={substationMarker}
          eventHandlers={{ click: openSubstationMarker }}
        />
      )}

      {showpointDetailBoxSubstation && nearestSubstation && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
            width: "250px",
            textAlign: "left",
          }}
        >
          <button
            onClick={closeSubstationMarker}
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              background: "none",
              border: "none",
              fontSize: "14px",
              cursor: "pointer",
              color: "#555",
            }}
          >
            ✖
          </button>

          <p className="m-0">
            <strong>Substation Name:</strong>{" "}
            {nearestSubstation.ss_name || "Unknown Substation"}
          </p>
          <p className="m-0">
            <strong>Substation ID:</strong> {nearestSubstation.ss_id}
          </p>
          <p className="m-0">
            <strong>Voltage:</strong> {nearestSubstation.ss_voltage} kV
          </p>
          <p className="m-0">
            <strong>Approx Distance:</strong>{" "}
            {parseFloat(nearestSubstation.distance).toFixed(2)} km
          </p>

          <div className="text-center mt-2">
            <button
              onClick={() => {
                setSubstationMarker(null);
                setShowPointDetailBoxSubstation(false);
              }}
              className="btn btn-sm btn-danger p-1"
              style={{ fontSize: "0.7rem" }}
            >
              Remove Marker
            </button>
          </div>
        </div>
      )}

      {locationMarker && (
        <Marker
          position={locationMarker}
          eventHandlers={{ click: openLocationMarker }}
        />
      )}

      {showpointDetailBoxLocation && nearestLocation && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
            width: "250px",
            textAlign: "left",
          }}
        >
          <button
            onClick={closeLocationMarker}
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              background: "none",
              border: "none",
              fontSize: "14px",
              cursor: "pointer",
              color: "#555",
            }}
          >
            ✖
          </button>

          <p className="mb-1">
            <strong>Location ID:</strong>{" "}
            {nearestLocation?.id || "Unknown Substation"}
          </p>

          <p className="mb-1 text-left">
            <strong>Location Id</strong> {nearestLocation?.id || "N/A"}
          </p>
          <p className="mb-1 text-left">
            <strong>Project Id</strong> {nearestLocation?.project_id || "N/A"}
          </p>

          <p className="mb-1 text-left">
            <strong>Device Type</strong> {nearestLocation?.point_type || "N/A"}
          </p>

          <p className="mb-1 text-left">
            <strong>Location Name:</strong>{" "}
            {nearestLocation.point_props?.location_name || "N/A"}
          </p>
          <p className="mb-1 text-left">
            <strong>Approx Distance:</strong>{" "}
            {parseFloat(nearestLocation.distanceMeters).toFixed(2)} meters
          </p>
          <div className="text-center mt-2">
            <button
              onClick={() => {
                setLocationMarker(null);
                setShowPointDetailBoxLocation(false);
              }}
              className="btn btn-sm btn-danger p-1"
              style={{ fontSize: "0.7rem" }}
            >
              Remove Marker
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchLatLangString;
