// Sidebar.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import "./css/Sidebar.css";
// import { FaSyncAlt } from "react-icons/fa"; // Import refresh icon from FontAwesome
import axios from "axios";
// import { circle } from "leaflet";
import StyleModal from "./StyleModal/StyleModal";
// import GeoJsonStyleModal from "./StyleModal/GeoJsonStyleModal";
// import { MdPadding } from "react-icons/md";
// import { IoIosInformationCircleOutline } from "react-icons/io";
// import LocationInfo from "./LocationInfo";
import InfoModel from "./InfoModel/InfoModel";
// import turf from 'leaflet.markercluster'
import * as turf from "@turf/turf";
// import { info } from "sass";
// import { CiCircleMore } from "react-icons/ci";
// import LineModel from "./LineNetworkModal/LineModel";
import UploadModal from "../UploadModal/UploadModal";
// import { IoReloadCircle } from "react-icons/io5";
// import LoadingSpinner from "./LoadingSpinner";
// import { isTransformEffect } from "html2canvas/dist/types/render/effects";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
const baseUrl = "https://geoserver.fornaxenergytech.com";
const mapUrl = process.env.REACT_APP_DRAW_API_URL;

const LeftSidebar = ({
  // sendDataToParent,
  setMapLayer,
  drawGeoJSON,
  updateCenter,
  addMerkers,
  handleShowObject,
  // objectElements,
  // toggleObjectElements,
  handleGeneratePDF,
  handleDrawPointType,
  // activeBaseLayer,
  // handleDistrictBaseLayerChange,
  // selectedTaluka,
  // handleTalukaBaseLayerChange,
  isTalukaVisible,
  //  isDistrictVisble,
  //  selectedDistrictId,
  errorMessage,
  handleRefresh,
  handleBaseLayerChange,
  baseLayer,
  //  setFilteredData,
  // setBaselayerVisble,
  // setLayerVisibility,
  setCircleData,
  //  circleData,
  //  setFilterEHVJson,
  setInfraStructure,
  infraStructure,
  handleClearNetwork,
  //  handleDistrictStyle
  setDistrictStyle,
  districtStyle,
  setTalukaStyle,
  talukaStyle,
  setHighwayStyle,
  highwayStyle,
  // handleClearNetworkList,
  handleClearLineNetwork,
  organization,
  setOrganization,

  organizations,
  setOrganizations,
  divisions,
  setDivisions,
  subDivisions,
  setSubDivisions,
  setCompanyStyle,
  companyStyle,
  setCompanyData,
  // setSubstationIds,
  stnIdsHandler,
  setDivisionData,
  setSubDivisionData,
  setCircleStyle,
  setDivisionStyle,
  setSubDivisionStyle,
  circleStyle,
  divisionStyle,
  subDivisionStyle,
  searchData,
  // setDiscomLocationData,
  setGujaratHighwayData,
  selectedPointType,
  setSelectedPointType,
  subMenuSection,
  setSubMenuSection,
  clearnetwork,
  handleMeasureChange,
  measureMode,
  measureDetail,
  // setMeasureMode,
  handleAreaPrint,
  // isLineDataChecked,
  // setIsLineDatachecked,
  // setIsshowIds,
  isshowssIds,
  // setColor,
  // color,
  waterStyle,
  setwaterStyle,
  setWaterLayerData,
  setRailwayLayerData,
  setReserverLayerData,
  reserveStyle,
  setReserveStyle,
  railStyle,
  setRailStyle,
  isGujaratBoundary,
  setIsGujaratBoundary,
  // transformerCheckedIds,
  // setTransformerCheckedIds,
  // subStatation,
  maploading,
  success,
  onStnsSelect,
  setFlyname,
}) => {

  // console.log(drawGeoJSON);
  const leftSubmenuRef = useRef(null);
  const [activeLayer, setActiveLayer] = useState("Canvas");

  const [substationList, setSubstationList] = useState([]);

  const [selectedProjects, setSelectedProjects] = useState([]);
  // const [projects, setprojects] = useState([]);
  // const [toggleStates, setToggleStates] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(1);
  // const [options, setOptions] = useState([]);

  // const [eHVLineData, setEHVLineData] = useState();
  
  const [filterData, setFilteredData] = useState();
  // const [selectedPointType, setSelectedPointType] = useState([]);
  // const [refreshing, setRefreshing] = useState(false);
  // const [formData, setFormData] = useState({
  //   project_id: "",
  // });

  const [isForm, setIsForm] = useState(false);

  const [openblAccordion, setOpenblAccordion] = useState();

  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    // filterData();

    if (searchData.project_id) {
      if (searchData.category == "Company") {
        handleOrganizationChange("company", searchData.project_id);
      } else if (searchData.category == "Circle") {
        handleOrganizationChange("circle", searchData.project_id);
      } else if (searchData.category == "Division") {
        handleOrganizationChange("division", searchData.project_id);
      } else if (searchData.category == "Sub Division") {
        handleOrganizationChange("subDivision", searchData.project_id);
      } else {
        window.alert("You are not Select any Category");
      }
      // console.log("searchData.project_id",searchData.project_id);
    }
  }, [searchData]);

  // const[districtStyle, setDistrictStyle] = useState({color:'black', opacity:1});

  // const[activeBaseLayer,setActiveBaseLayer] = useState();

  useEffect(() => {
    // Fetch categories from API
    fetch(process.env.REACT_APP_API_URL + "/api/get-organazations")
      .then((response) => response.json())
      .then((result) => {
        setOrganizations(result.data); // Assuming the API response is an array of category objects
        // setDivisions(result.data.divisions);
        setDivisions(
          result.data.divisions.sort((a, b) => a.label.localeCompare(b.label)) // Sort by the label property
        );

        setSubDivisions(
          result.data.subDivisions.sort((a, b) =>
            a.label.localeCompare(b.label)
          )
        );

        // console.error(result.data);
      })
      .catch((error) => {
        console.error("Error fetching organizations:", error);
      });
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const username = "admin";
  const password = "geoserver";

  // useEffect(() => {
  //   axios
  //     .get(`${baseUrl}/geoserver/wfs`, {
  //       params: {
  //         service: "WFS",
  //         version: "1.0.0",
  //         request: "GetFeature",
  //         typeName: "discom:tmp_ht_lines",
  //         outputFormat: "application/json",
  //       },
  //       auth: {
  //         username: username,
  //         password: password,
  //       },
  //     })
  //     .then((res) => {
  //       // console.log("Response Data 123:", res.data);

  //       setEHVLineData(res.data);
  //     });
  // }, []);

  // useEffect(() => {
  //   console.log(searchData);
  //   // setOrganization
  // }, [searchData]);

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        // setLoading(true); // Set loading to true
        const response = await axios.get(`${baseUrl}/geoserver/wfs`, {
          params: {
            service: "WFS",
            version: "1.0.0",
            request: "GetFeature",
            typeName: "discom:organization",
            outputFormat: "application/json",
          },
          auth: {
            username: username,
            password: password,
          },
        });

        // console.error("Response Data:", response.data);

        // Process and cache data if necessary
        setFilteredData(response.data);
        // setLoading(false); // Set loading to false
      } catch (error) {
        console.error("Error fetching data:", error);
        // setError(error); // Set error state
        // setLoading(false); // Set loading to false
      }
    };

    fetchData();
  }, [baseUrl, username, password]);
  // useEffect()
  // console.log(filteredData);

  // Function to handle toggle switch change
  // const handleToggleChange = (index) => {
  //   const newToggleStates = [...toggleStates];
  //   newToggleStates[index] = !newToggleStates[index];
  //   setToggleStates(newToggleStates);
  // };

  // Function to add a new toggle switch
  // const addToggleSwitch = () => {
  //   setToggleStates([...toggleStates, false]);
  // };

  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  //   setSelectedProjects([
  //     {
  //       project_id: e.target.value,
  //     },
  //   ]);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLayerVisibility(true);
  //   try {
  //     // const responseLine = await fetch(`api/get-project-line-data/${formData.project_id}`);
  //     // const line = await responseLine.json();
  //     const projectIds = selectedProjects
  //       .map((item) => item.project_id)
  //       .join(",");

  //     // console.log("projectIds", projectIds);
  //     const myHeaders = new Headers();

  //     myHeaders.append("Accept", "application/json");

  //     const formdataAPI = new FormData();
  //     formdataAPI.append("project_ids", projectIds);

  //     const requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: formdataAPI,
  //       redirect: "follow",
  //     };

  //     const responseLine = await fetch(
  //       process.env.REACT_APP_API_URL + "/api/get-project-line-data",
  //       requestOptions
  //     );
  //     const line = await responseLine.json();

  //     const response = await fetch(
  //       process.env.REACT_APP_API_URL + "/api/get-project-point-data",
  //       requestOptions
  //     );
  //     const data = await response.json();

  //     if (data.features === null) {
  //       window.alert("No Data found.");
  //       setFormData({
  //         project_id: "",
  //       });
  //       throw new Error("An error occurred!");
  //     }
  //     var array = [];
  //     data.features.map((value, index) => {
  //       if (value.properties.point_type === "HT Pole") {
  //         if (!array.includes("Support Pole")) {
  //           array.push("Support Pole");
  //         }
  //       } else {
  //         if (!array.includes(value.properties.point_type)) {
  //           array.push(value.properties.point_type);
  //         }
  //       }
  //     });
  //     // setOptions(array);
  //     // sendDataToParent(data, line);

  //     // const filtered = eHVLineData.features.filter(feature => projectIds.includes(feature.properties.project_id));

  //     // console.log("Filter EHV Line ",filtered);
  //     // setFilterEHVJson(filtered);

  //     // danish
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const [formData2, setFormData2] = useState({
    object_id: "",
    object_name: "",
    measurement: 0,
    type: "",
    geom: "",
    user_id: "",
  });

  useEffect(() => {
    // console.log(measureDetail);
    if (measureDetail) {
      setFormData2({
        object_id: measureDetail?.id || "", // Ensure id is properly set or default to an empty string
        object_name: measureDetail?.name || "", // Default to empty string if measureDetail.name is undefined
        measurement: measureDetail?.measurement || 0, // Default to empty string if measureDetail.measurement is undefined
        type: measureDetail?.type || "", // Default to empty string if measureDetail.type is undefined
        geom: measureDetail?.geom || "",
        user_id: 1,
        object_type:'',
      });
    }
  }, [measureDetail]);

  const handleChange1 = (e) => {
    const { id, value } = e.target;
    setFormData2({
      ...formData2,
      [id]: value,
    });
    console.log(id);
    if([id] == 'object_type'){
      handleDrawPointType(measureDetail?.name,value);
    }
  };

  //   useEffect(()=>{

  //  setOrganization({company:'UGVCL'})
  //   },[])

  // Event handler for category select change
  const handleOrganizationChange = (level, orgItem) => {
    // console.log("orgItem",orgItem);
    setOrganization((prevOrganization) => {
      const existingItems = prevOrganization[level] || [];
      const isItemExist = existingItems.some(
        (item) => item.value === orgItem.value
      );

      // console.log("is Existance infraStructure",isItemExist);

      if (isItemExist) {
        // If the item already exists, remove it
        return {
          ...prevOrganization,
          [level]: existingItems.filter((item) => item.value !== orgItem.value),
        };
      } else {
        // If the item doesn't exist, add it
        return {
          ...prevOrganization,
          [level]: [...existingItems, orgItem],
        };
      }
    });
  };

  // const isInfraValueExist = (array, value) => array.includes(value);

  const handleInfraStructureChange = (newItem) => {
    setInfraStructure((prevInfraStructure) => {
      // Check if the item already exists
      if (prevInfraStructure.includes(newItem)) {
        // Remove the item if it exists
        return prevInfraStructure.filter((item) => item !== newItem);
      } else {
        // Add the item if it doesn't exist
        return [...prevInfraStructure, newItem];
      }
    });

    // if(!infraStructure.includes("discom_location")){

    //   setSubMenuSection("objects");
    //   setSelectedPointType(["Transformer"]);

    // }
    // else{
    //   setSelectedPointType([]);

    // }
  };

  // console.log(infraStructure);

  // const [infraStructureData,setInfraStructureData] = useState({});

  // const addInfraStructure = (key, newItem) => {
  //   setInfraStructureData(prevItems => ({
  //     ...prevItems,
  //     [key]: newItem
  //   }));
  // };

  // useEffect(()=>{

  //   axios
  //   .get(`${baseUrl}/geoserver/wfs`, {
  //     params: {
  //       service: 'WFS',
  //       version: '1.0.0',
  //       request: 'GetFeature',
  //       typeName: 'Gis_Gujarat:gujarat_highway',
  //       outputFormat: 'application/json'
  //     },
  //     auth: {
  //       username: username,
  //       password: password
  //     }
  //   }).then((response) =>{

  //     console.log("Highway",response.data);

  //     addInfraStructure("highway",response.data);

  //   }).catch((e)=>{
  //     console.log("Error From Highway Layer",e);
  //   })

  //   axios
  //   .get(`${baseUrl}/geoserver/wfs`, {
  //     params: {
  //       service: 'WFS',
  //       version: '1.0.0',
  //       request: 'GetFeature',
  //       typeName: 'Gis_Gujarat:gujarat_water',
  //       outputFormat: 'application/json'
  //     },
  //     auth: {
  //       username: username,
  //       password: password
  //     }
  //   }).then((response) =>{

  //     console.log("Highway",response.data);

  //     addInfraStructure("highway",response.data);

  //   }).catch((e)=>{
  //     console.log("Error From Highway Layer",e);
  //   })
  // },[]);

  useEffect(() => {
    setDivisions(filterDivisions());

    // if (subStatation && subStatation.length > 0) {
    //   const ssidArray = subStatation.map((substation) => substation.ss_id);
    //   stnIdsHandler(ssidArray);
    // }

    if (substationList.some((e) => "is_selected" in e && e.is_selected)) {
      const ssidArray = substationList
        .filter((e) => "is_selected" in e && e.is_selected)
        .map((e) => e.ss_id);
      stnIdsHandler(ssidArray);
    }
  }, []);

  useEffect(() => {
    // Code to execute after organization state has been updated

    // if(diversion)
    setSubDivisions(filtersubDivisions());
  }, [divisions]);

  useEffect(() => {

    const selectedStns = substationList.filter((e) => 'is_selected' in e && e.is_selected);
    addMerkers(selectedStns);
    // .0.0Code to execute after organization state has been updated
    // const formdata = new FormData();
    // formdata.append(
    //   "ss_ids",
    //   subStatation.map((item) => item.value)
    // );
    // const requestOptions = {
    //   method: "POST",
    //   body: { ss_ids: [subStatation.map((item) => item.value)] },
    //   redirect: "follow",
    // };
    // fetch(
    //   process.env.REACT_APP_API_URL + `/api/get-projects-of-substation`,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log(result.data);
    //     // setprojects(result.data); // Assuming the API response is an array of category objects
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching Projects of substation:", error);
    //   });
  }, [substationList]);

  // // Function to filter subdivisions based on parent organization code
  // function filterCircles() {
  //   if (organization?.company) {
  //     const filteredCircles = [];
  //     const organizationCodes = organization.company.map(
  //       (item) => item.organization_code
  //     );
  //     organizationCodes.forEach((parentOrganizationCode) => {
  //       const parentCodeString = parentOrganizationCode.toString().substr(0, 2); // Extract first 4 digits
  //       const circle = organizations.circle.filter((circle) => {
  //         const circleCodeString = circle.organization_code.toString();
  //         // Check if the sub-division's organization code matches the pattern
  //         return circleCodeString.startsWith(parentCodeString);
  //       });
  //       filteredCircles.push(...circle);
  //     });
  //     // console.log("Circle",filteredCircles);

  //     return filteredCircles;
  //   }
  // }

  function filterDivisions() {
    const filteredDivisions = [];
    if (organization?.circle) {
      const organizationCodes = organization.circle.map(
        (item) => item.organization_code
      );
      organizationCodes.forEach((parentOrganizationCode) => {
        const parentCodeString = parentOrganizationCode.toString().substr(0, 4); // Extract first 4 digits
        const divisions = organizations.divisions.filter((division) => {
          const divisionCodeString = division.organization_code.toString();
          // Check if the sub-division's organization code matches the pattern
          return divisionCodeString.startsWith(parentCodeString);
        });
        filteredDivisions.push(...divisions);
      });
    }
    return filteredDivisions.length
      ? filteredDivisions
      : organizations.divisions;
  }

  function filtersubDivisions() {
    const filteredSubDivisions = [];
    if (organization?.division) {
      const organizationCodes = organization.division.map(
        (item) => item.organization_code
      );
      organizationCodes.forEach((parentOrganizationCode) => {
        const parentCodeString = parentOrganizationCode.toString().substr(0, 6); // Extract first 4 digits
        const subDivisions = organizations.subDivisions.filter(
          (subDivision) => {
            const subDivisionCodeString =
              subDivision.organization_code.toString();
            // Check if the sub-division's organization code matches the pattern
            return subDivisionCodeString.startsWith(parentCodeString);
          }
        );
        filteredSubDivisions.push(...subDivisions);
      });
    }
    return filteredSubDivisions.length
      ? filteredSubDivisions
      : organizations.subDivisions;
  }

  const handleSubStatationChange = (selectedStn) => {
    setFlyname(null);
    // const index = subStatation.findIndex(
    //   (item) => JSON.stringify(item) === JSON.stringify(subStatationJson)
    // );

    const tmpStnList = JSON.parse(JSON.stringify(substationList));
    const tmpSelectedStnIdx = tmpStnList.findIndex(
      (e) => e.ss_id == selectedStn.ss_id
    );
    if ("is_selected" in tmpStnList[tmpSelectedStnIdx]) {
      tmpStnList[tmpSelectedStnIdx].is_selected =
        !tmpStnList[tmpSelectedStnIdx].is_selected;
    } else {
      tmpStnList[tmpSelectedStnIdx].is_selected = true;
    }

    setSubstationList(tmpStnList);
    onStnsSelect(tmpStnList.filter((e) => "is_selected" in e && e.is_selected));
    // console.log(tmpStnList);
    return;

    // setSelectedProjects([]);
    // setprojects([]);

    // setSubMenuSection("objects");
    // setSelectedPointType(["Transformer"]);

    // console.log("filterCircles()",subStatation);
  };

  useEffect(() => {
    setSelectedProjects([]);
    // setSubStatation([]);
    // setCheckedState([]);
    // setTransformerState([]);
    // console.log("CLear Function work");
  }, [clearnetwork]);

  // // Danish
  // const handleProjectChange = (projectJson) => {
  //   //setSelectedProjects([selectedProjects]);
  //   //console.log(selectedProjects);
  //   const index = selectedProjects.findIndex(
  //     (item) => JSON.stringify(item) === JSON.stringify(projectJson)
  //   );

  //   if (index === -1) {
  //     // JSON not present, add it to the array
  //     setSelectedProjects((prevArray) => [...prevArray, projectJson]);
  //   } else {
  //     // JSON already present, remove it from the array
  //     setSelectedProjects((prevArray) => {
  //       const newArray = [...prevArray];
  //       newArray.splice(index, 1);
  //       return newArray;
  //     });
  //   }
  // };

  // const [companyModal, setCompanyModal] = useState(false);
  // const [circleModal, setCircleModal] = useState(false);
  // const [divisionModal, setDivisionModal] = useState(false);
  // const [subDivision, setSubDivision] = useState(false);
  // const [isLineModal, setIsLineModal] = useState(false);

  const toggleAccordion = (accordionNumber) => {
    setOpenAccordion(
      openAccordion === accordionNumber ? null : accordionNumber
    );
  };

  const toggleblAccordion = (accordionNumber) => {
    setOpenblAccordion(
      openblAccordion === accordionNumber ? null : accordionNumber
    );
  };
  // useEffect(() => {

  //   console.log("openAccordion",openAccordion);
  //   switch (openAccordion) {
  //     case "company":
  //       setCompanyModal(false);
  //       break;
  //     case "circle":
  //       setCircleModal(false);
  //       break;
  //     default:

  //       // setCompanyModal(true);
  //       // setCircleModal(true);
  //   }
  // }, [openAccordion]);

  const removeClass = () => {
    if (leftSubmenuRef.current) {
      leftSubmenuRef.current.classList.remove("visible");
      setSubMenuSection(null);
    }
  };

  // const openLeftSubmenu = () => {
  //   if (!isClassAdded) {
  //     setIsClassAdded(true);
  //   }
  // };

  const openLeftSubmenu = (section) => {
    if (subMenuSection === section) {
      setSubMenuSection(null);

      // handleBaseLayerChange();
    } else {
      setSubMenuSection(section);
    }
  };

  const handleOrganizationNetwork = () => {
    let organizationId = [93000000];

    if (organization.hasOwnProperty("subDivision")) {
      organizationId = organization.subDivision.map(
        (item) => item.organization_code
      );
    } else if (organization.hasOwnProperty("division")) {
      organizationId = organization.division.map(
        (item) => item.organization_code
      );
    } else if (organization.hasOwnProperty("circle")) {
      organizationId = organization.circle.map(
        (item) => item.organization_code
      );
    } else if (organization.hasOwnProperty("company")) {
      organizationId = organization.company.map(
        (item) => item.organization_code
      );
    }

    // console.log("Filtered Organization", filterData);

    // const id = [93000000];
    // setOrganizationId(organizationId)
    // console.log("Organization ID: ",organizationId);

    // console.log("filterCi",filtered);
    // console.log("Organisation Ids Data", organizationId);

    if (organizationId) {
      const formdata = new FormData();
      formdata.append("organization_code", organizationId);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(
        process.env.REACT_APP_API_URL + `/api/get-substation-of-organization`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          var latLongArray = result.data.map((item) => [item.lat, item.lng]);
          updateCenter(latLongArray);
          // setsubStatations(result.data); // Assuming the API response is an array of category object
          setSubstationList(result.data);
        })
        .catch((error) => {
          console.error("Error fetching sub stations:", error);
        });
        if(subMenuSection == 'organization'){
        openLeftSubmenu("network");
    }
     
    } else {
      window.alert("Please select an organization.");
    }
    //console.log(subStatations);
  };

  useEffect(() => {
   
      handleOrganizationNetwork();
  },[])

  const handleMapChange = (mapImage) => {
    setMapLayer(mapImage);
    setActiveLayer(mapImage);
  };

  // Function to check if an organization value exists in the Organizations
  const isValueExist = (array, targetValue) => {
    return array.some((item) => item.value === targetValue);
  };

  // const [style, setStyle] = useState();
  // const handleDistrictStyle = () => {
  //   // setDistrictStyle({ color, opacity });
  // };
  // const handleStyleChange = (company, style) => {
  //   setCompanyStyle((prevStyles) => ({
  //     ...prevStyles,
  //     [company]: style,
  //   }));
  // };

  // const pointTypesData = {
  //   Transformer: "triangleSVG.svg",
  //   Fuse: "circleSVG.svg",
  //   Gentry: "squareSVG.svg",
  //   // "HT Pole": "starSVG.svg",
  //   RMU: "pentagon.svg",
  //   "HT Route Point": "hexagonSVG.svg",
  //   "RMU with TC": "ellipseSVG.svg",
  //   Switch: "diamondSVG.svg",
  //   CTPT: "crossSVG.svg",
  //   // "Existing Pole(Double Circuit)": "xSVG.svg",
  //   // Add mappings for other point types here
  // };
  const pointTypesData = {
    Transformer: `${mapUrl}/map_icon/Transformer.svg`,
    Fuse: `${mapUrl}/map_icon/Fuse.svg`,
    Gentry: `${mapUrl}/map_icon/Gentry.svg`,
    // "HT Pole": "StarSVG.svg",
    RMU: `${mapUrl}/map_icon/RMU.svg`,
    "HT Route Point": `${mapUrl}/map_icon/ht_route_point.svg`,
    "RMU with TC": `${mapUrl}/map_icon/rmu_with_tc.svg`,
    Switch: `${mapUrl}/map_icon/Switch.svg`,
    CTPT: `${mapUrl}/map_icon/CTPT.svg`,
    "Existing Pole(Double Circuit)": `${mapUrl}/map_icon/existing_pole.svg`,
    // Add mappings for other point types here
  };

  const pointTypesArray = Object.entries(pointTypesData);

  useEffect(() => {
    if (filterData) {
      if (Array.isArray(organization.company)) {
        // Handle case where organization.company is an array
        const companyCodes = organization.company.map(
          (comp) => comp.organization_code
        );

        const filtered = filterData.features.filter((feature) =>
          companyCodes.includes(feature.properties.organization_code)
        );

        // console.error("filtered company Data ");
        if (organization.company.length > 0) {
          setCompanyData(filtered);
        } else {
          setCompanyData([]);
        }
      } else if (
        organization.company &&
        typeof organization.company === "object"
      ) {
        // Handle case where organization.company is an object
        const filtered = filterData.features.filter(
          (feature) =>
            feature.properties.organization_code ===
            organization.company.organization_code
        );
        // setCompanyData(filtered);
      }

      // Assuming circle is an array of IDs, adjust as needed
      if (organization.circle && Array.isArray(organization.circle)) {
        const circleIds = organization.circle.map(
          (circle) => circle.organization_code
        );

        const filteredCircleData = filterData.features.filter((feature) =>
          circleIds.includes(feature.properties.organization_code)
        );

        if (organization.circle.length > 0) {
          setCircleData(filteredCircleData);
        } else {
          setCircleData([]);
        }
      }

      if (organization.division && Array.isArray(organization.division)) {
        const divisionIds = organization.division.map(
          (division) => division.organization_code
        );

        const filteredDivisionData = filterData.features.filter((feature) =>
          divisionIds.includes(feature.properties.organization_code)
        );
        setDivisionData(filteredDivisionData);
      }

      if (organization.subDivision && Array.isArray(organization.subDivision)) {
        const divisionIds = organization.subDivision.map(
          (subDivision) => subDivision.organization_code
        );

        const filteredsubDivisionData = filterData.features.filter((feature) =>
          divisionIds.includes(feature.properties.organization_code)
        );

        console.error("filteredsubDivisionData", filteredsubDivisionData);
        if (organization?.division && organization.division.length > 0) {
          setSubDivisionData(filteredsubDivisionData);
        } else {
          setSubDivisionData([]);
        }
      }
    }
  }, [filterData, organization]);

  const [infoData, setInfoData] = useState();
  const [infoResult, setInfoResult] = useState([]);
  const [isInfoModal, setIsInfoModal] = useState(false);
  const handleClose = () => {
    setIsInfoModal(false);
  };

  const getAreaInSquareKm = (geometry) => {
    if (geometry.type === "MultiPolygon" || geometry.type === "Polygon") {
      // Calculate area using turf (in square meters)
      const areaInSquareMeters = turf.area(geometry);

      // Convert square meters to square kilometers
      const areaInSquareKm = areaInSquareMeters / 1_000_000;

      return areaInSquareKm.toFixed(2);
    }
    return 0; // Return 0 if it's not a valid polygon or multipolygon
  };

  const filterInfoData = useCallback(() => {
    if (!infoData) return null; // Ensure infoData exists

    const infoFilteredData = filterData?.features.filter(
      (feature) => feature.properties.organization_code === infoData
    );

    if (infoFilteredData.length > 0) {
      const newFilteredData = { ...infoFilteredData[0].properties };

      if (infoFilteredData[0]?.geometry) {
        const area = getAreaInSquareKm(infoFilteredData[0].geometry);
        return { ...newFilteredData, area };
      }
      return newFilteredData;
    }
    return null;
  }, [infoData, filterData]); // ✅ Only re-run when infoData or filterData changes

  useEffect(() => {
    try {
      const finalData = filterInfoData();
      setInfoResult(finalData);
    } catch (error) {
      console.error("Error in useEffect:", error);
    }
  }, [filterInfoData]); // ✅ Only re-run when filterInfoData changes

  const handleInfo = (code) => {
    // console.log("this working");
    setIsInfoModal(true);
    setInfoData(code);
  };

  // const [pointType, setPointType] = useState(new Set());
  // const [locationData, setLocationData] = useState([]);

  const [isUploadModal, setIsUploadModal] = useState(false);

  const handleUploadModal = () => {
    setIsUploadModal(true);
  };

  const fetchData = useCallback(async () => {
    try {
      if (infraStructure.includes("water_body")) {
        try {
          const response = await axios.get(
            "https://geoserver.fornaxenergytech.com/geoserver/wfs",
            {
              params: {
                service: "WFS",
                version: "1.0.0",
                request: "GetFeature",
                typeName: "Gis_Gujarat:water_body",
                outputFormat: "application/json",
              },
            }
          );
          setWaterLayerData(response.data);
          // console.log("Gujarat Highway", response.data);
        } catch (error) {
          console.error("Error fetching gujarat_highway data:", error);
          setWaterLayerData(null);
        }
      } else {
        setWaterLayerData(null);
      }

      if (infraStructure.includes("naturalReserve")) {
        try {
          const response = await axios.get(
            "https://geoserver.fornaxenergytech.com/geoserver/wfs",
            {
              params: {
                service: "WFS",
                version: "1.0.0",
                request: "GetFeature",
                typeName: "Gis_Gujarat:naturalReservoir",
                outputFormat: "application/json",
              },
            }
          );
          setReserverLayerData(response.data);
          // console.log("Gujarat Natural", response.data);
        } catch (error) {
          console.error("Error fetching gujarat_highway data:", error);
          setReserverLayerData(null);
        }
      } else {
        setReserverLayerData(null);
      }

      if (infraStructure.includes("railway")) {
        try {
          const response = await axios.get(
            "https://geoserver.fornaxenergytech.com/geoserver/wfs",
            {
              params: {
                service: "WFS",
                version: "1.0.0",
                request: "GetFeature",
                typeName: "Gis_Gujarat:rail",
                outputFormat: "application/json",
              },
            }
          );
          setRailwayLayerData(response.data);
          // console.log("Gujarat Rail", response.data);
        } catch (error) {
          console.error("Error fetching gujarat_highway data:", error);
          setRailwayLayerData(null);
        }
      } else {
        setRailwayLayerData(null);
      }

      if (infraStructure.includes("gujarat_highway")) {
        try {
          const response = await axios.get(
            "https://geoserver.fornaxenergytech.com/geoserver/wfs",
            {
              params: {
                service: "WFS",
                version: "1.0.0",
                request: "GetFeature",
                typeName: "Gis_Gujarat:gujarat_highway",
                outputFormat: "application/json",
              },
            }
          );
          setGujaratHighwayData(response.data);
          // console.log("Gujarat Highway", response.data);
        } catch (error) {
          console.error("Error fetching gujarat_highway data:", error);
          setGujaratHighwayData(null);
        }
      } else {
        setGujaratHighwayData(null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [infraStructure]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClearInfrastructure = () => {
    setInfraStructure([]);
  };

  const handlePointChange = (name) => {
    setSelectedPointType((prevSelected) => {
      if (prevSelected.includes(name)) {
        // If the name is already selected, remove it
        return prevSelected.filter((item) => item !== name);
      } else {
        // If the name is not selected, add it
        return [...prevSelected, name];
      }
    });
    // console.log(`Selected point types: ${selectedPointType}`);
  }; // } else if (organization.hasOwnProperty("division")) {

  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);

  const [savedData, setSavedData] = useState([]);

  useEffect(() => {
    if (measureDetail) {
      if (savedData.includes(measureDetail.name)) {
        setIsSaved(true);
      } else {
        setIsSaved(false);
      }
    }
  }, [savedData, measureDetail]);

  const SaveDraw = () => {
    const storedAuth = JSON.parse(localStorage.getItem("_auth"));
    console.log(measureDetail.geom);
    //@author dwiraj Chauhan
    const newFormdata = new FormData();
    const fields = [
      "object_id",
      "object_name",
      "object_type",
      "measurement",
      "type",
     
    ];
    fields.forEach((field) => newFormdata.append(field, formData2[field]));
    newFormdata.append("geom", measureDetail.geom);
    newFormdata.append( "user_id",storedAuth?.UserName)
    // Append the first file from the uploadedFiles array
    if (uploadedFiles && uploadedFiles.length > 0) {
      newFormdata.append("document", uploadedFiles[0]); // Upload only the first file
    }
    const requestOptions = {
      method: "POST",
      body: newFormdata,
      redirect: "follow",
    };
    const formDataObject = Object.fromEntries(newFormdata.entries());
    // console.log(formDataObject);

    fetch(process.env.REACT_APP_DRAW_API_URL + `/save-draw-object`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result); 
        setIsSaved(true);
        setSavedData([...savedData, measureDetail.name]);
      })
      .catch((error) => {
        console.error("Error While Save Object", error);
      });
    /**------------------------------------ */
  };

  const [isStyleModal, setIsStyleModal] = useState(false);

  const[isFindDraw,setIsFindDraw] = useState(false);
  const[objectId,setObjectId] = useState(null);
const[responseDraw, setResponseDraw] = useState([]);
  const findDrawLayer= async() => {
    console.log('Function call!');
    try {
      const response = await axios.get(`${mapUrl}/fetch-draw-objects/${objectId}`);
      console.error('fetching data:', response.data.geojson);
      
      setResponseDraw(response.data.geojson);
    } catch (error) {
      console.error('Error fetching data:', error);
      setResponseDraw([]);
    }
  }

  const handleDeleteShape = async () => {
    try {
      const response = await axios.delete(`${mapUrl}/delete-draw-objects/${objectId}`);
      console.error('fetching data:', response.data);
      
      setResponseDraw([]);
      handleShowObject("draw-objects")
    } catch (error) {
      console.error('Error fetching data:', error);
      // setResponseDraw([]);
    }
  }

//  console.log(responseDraw?.features?.length); 
  // const [modalOpenIndex, setModalOpenIndex] = useState(null); // Track modal open for each substation
  // const [checkedState, setCheckedState] = useState({}); // Track checkbox state for each substation

  // const [tranformerState, setTransformerState] = useState({});
// console.log('measureDetail',measureDetail.type);
  return (
    <div className="leftsidebar">
      <div className="main-menu">
        <ul className="leftsidebar-menu">
          <li className={`${subMenuSection == "organization" ? "active" : ""}`}>
            <a
              onClick={() => openLeftSubmenu("organization")}
              title="Organization"
            >
              <img src="organization.svg" className="sidebar-icon" alt="" />
              <span>Organization</span>
            </a>
            {/* <ReactTooltip id="OrganizationTip" place="right" effect="solid">
                            Organization
                        </ReactTooltip> */}
          </li>
          <li className={`${subMenuSection == "network" ? "active" : ""}`}>
            <a onClick={() => openLeftSubmenu("network")} title="Network">
              <img src="Network.svg" className="sidebar-icon" alt="" />
              <span>Network</span>
            </a>
          </li>
          <li className={`${subMenuSection == "base-map" ? "active" : ""}`}>
            <a onClick={() => openLeftSubmenu("base-map")} title="Basemap">
              <img src="Base-map.svg" className="sidebar-icon" alt=""  />
              <span>BaseMap</span>
            </a>
          </li>
          <li className={`${subMenuSection == "base-layer" ? "active" : ""}`}>
            <a onClick={() => openLeftSubmenu("base-layer")} title="BaseLayer">
              <img src="map-svgrepo-com.svg" className="sidebar-icon" alt="" />
              <span>BaseLayer</span>
            </a>
          </li>
          {/* 
                    <li className={`${subMenuSection == 'multi-layer' ? 'active' : ''}`}>
                        <a onClick={() => openLeftSubmenu("multi-layer")} title='multi-layer'>
                            <img src="infrastructure.svg" className="sidebar-icon" alt="" />
                            <span>MultiLayer</span>
                          </a>

                    </li> */}
          <li>
            <a onClick={() => openLeftSubmenu("draw")} title="Draw">
              <img src="Draw.svg" className="sidebar-icon" alt="draw" />
              <span>Draw</span>
            </a>
          </li>

          <li className={`${subMenuSection == "objects" ? "active" : ""}`}>
            <a onClick={() => openLeftSubmenu("objects")}>
              <img src="Objects.svg" className="sidebar-icon" alt="" />
              <span>Object</span>
            </a>
          </li>
          <li className={`${subMenuSection == "print" ? "active" : ""}`}>
            <a onClick={() => openLeftSubmenu("print")}>
              <img src="Print.svg" className="sidebar-icon" alt="" />
              <span>Print</span>
            </a>
          </li>
          {/* <li>
            <a href="#">
              <img
                src="report.svg"
                className="sidebar-icon"
                alt="reports"
                title="Reports"
              />
            </a>
          </li> */}
          <li>
            <a href="#">
              <img
                src="help.svg"
                className="sidebar-icon"
                alt="help"
                title="Help"
              />
            </a>
          </li>
        </ul>
      </div>
      <div
        ref={leftSubmenuRef}
        className={`sub-sidebar-wrap sub-menu ${
          subMenuSection !== null ? "visible" : ""
        }`}
      >
        {organizations.companies ? (
          <ul
            data-parent="organization"
            className={`${
              subMenuSection == "organization" ? "d-block" : "d-none"
            }`}
          >
            <li>
              <h6 className="sub-menu-title mb-0">Organization</h6>
            </li>
            <li>
              <div className="acoordion-wrap">
                <div
                  className={`accordion ${
                    openAccordion === "company" ? "open" : ""
                  }`}
                >
                  <div className="accordion-header">
                    <div
                      className="toggle"
                      onClick={() => toggleAccordion("company")}
                    >
                      <h3>
                        <img src="Company.svg" alt="" /> Company
                      </h3>
                    </div>
                    <div>
                      {openAccordion === "company" && (
                        <StyleModal
                          setStyle={setCompanyStyle}
                          style={companyStyle}
                          setIsStyleModal={setIsStyleModal}
                        />
                      )}
                    </div>
                    <span
                      className={`arrow ${
                        openAccordion === "company" ? "open" : ""
                      }`}
                    ></span>
                  </div>

                  {/* <div className="d-flex justify-content-end">
                  {openAccordion === "company" && ( <StyleModal setStyle={setCompanyStyle}  style={companyStyle}  />
                    )}
                    </div> */}
                  <div className="accordion-content">
                    <ul>
                      {organizations.companies.map((item, index) => (
                        <li key={index}>
                          <a
                            href="#"
                            onClick={() =>
                              handleOrganizationChange("company", item)
                            }
                            className={
                              isValueExist(
                                organization?.company || [],
                                item.value
                              )
                                ? "active"
                                : ""
                            }
                          >
                            {item.label}
                            {/* <GeoJsonStyleModal
              initialStyle={companyStyle[item.value] || {}}
              onSave={(style) => handleStyleChange(item.value, style)}
            /> */}
                          </a>
                          <a onClick={() => handleInfo(item.organization_code)}>
                            {/* Info */}
                            <IoIosInformationCircleOutline />
                          </a>
                          {/* <LocationInfo data={{ type: "company", name: item.organization_code }} setResult={false} /> */}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              {organizations.circle ? (
                <div className="acoordion-wrap">
                  <div
                    className={`accordion ${
                      openAccordion === "circle" ? "open" : ""
                    }`}
                  >
                    <div className="accordion-header">
                      <div
                        className="toggle"
                        onClick={() => toggleAccordion("circle")}
                      >
                        <h3>
                          <img src="Circle.svg" alt="" /> Circle
                        </h3>
                      </div>
                      <div>
                        {openAccordion === "circle" && (
                          <StyleModal
                            setStyle={setCircleStyle}
                            style={circleStyle}
                            setIsStyleModal={setIsStyleModal}
                          />
                        )}
                      </div>
                      <span
                        className={`arrow ${
                          openAccordion === "circle" ? "open" : ""
                        }`}
                      ></span>
                    </div>
                    {/* <div className="d-flex justify-content-end">
                    {openAccordion === "circle" && ( <StyleModal setStyle={setCircleStyle} style={circleStyle} /> )}
                      </div> */}
                    <div className="accordion-content">
                      <ul>
                        {organizations.circle.map((item, index) => (
                          <li key={index}>
                            <a
                              href="#"
                              onClick={() =>
                                handleOrganizationChange("circle", item)
                              }
                              className={
                                isValueExist(
                                  organization?.circle || [],
                                  item.value
                                )
                                  ? "active"
                                  : ""
                              }
                            >
                              {item.label}
                            </a>

                            <a
                              onClick={() => handleInfo(item.organization_code)}
                            >
                              <IoIosInformationCircleOutline />
                            </a>
                            {/* <LocationInfo data={{ type: "circle", name: item.organization_code }} setResult={false} /> */}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <label>No circle found</label>
              )}
            </li>
            <li>
              {organizations.divisions ? (
                <div className="acoordion-wrap">
                  <div
                    className={`accordion ${
                      openAccordion === "divisions" ? "open" : ""
                    }`}
                  >
                    <div className="accordion-header">
                      <div
                        className="toggle"
                        onClick={() => toggleAccordion("divisions")}
                      >
                        <h3>
                          <img src="Division.svg" alt="" /> Division
                        </h3>
                      </div>
                      <div>
                        {openAccordion === "divisions" && (
                          <StyleModal
                            setStyle={setDivisionStyle}
                            style={divisionStyle}
                            setIsStyleModal={setIsStyleModal}
                          />
                        )}
                      </div>

                      <span
                        className={`arrow ${
                          openAccordion === "divisions" ? "open" : ""
                        }`}
                      >
                        
                      </span>
                    </div>

                    <div className="accordion-content">
                      <ul>
                        {divisions.map((item, index) => (
                          <li key={index}>
                            <a
                              href="#"
                              onClick={() =>
                                handleOrganizationChange("division", item)
                              }
                              className={
                                isValueExist(
                                  organization?.division || [],
                                  item.value
                                )
                                  ? "active"
                                  : ""
                              }
                            >
                              {item.label}
                            </a>

                            <a
                              onClick={() => handleInfo(item.organization_code)}
                            >
                              <IoIosInformationCircleOutline />
                            </a>
                          </li>
                        ))}
                        {/* {organizations.divisions.map((item, index) => (
                                            <li key={index}><a href="#" onClick={() => handleOrganizationChange('division', item)} className={isValueExist(organization?.division || [], item.value) ? 'active' : ''}>{item.label}</a></li>
                                        ))} */}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <label>No division found</label>
              )}
            </li>
            <li>
              {organizations.subDivisions ? (
                <div className="acoordion-wrap">
                  <div
                    className={`accordion ${
                      openAccordion === "subDivisions" ? "open" : ""
                    }`}
                  >
                    <div className="accordion-header">
                      <div
                        className="toggle"
                        onClick={() => toggleAccordion("subDivisions")}
                      >
                        <h3>
                          <img src="Sub-division.svg" alt="" /> Sub Division
                        </h3>
                      </div>
                      <div>
                        {openAccordion === "subDivisions" && (
                          <StyleModal
                            setStyle={setSubDivisionStyle}
                            style={subDivisionStyle}
                            setIsStyleModal={setIsStyleModal}
                          />
                        )}
                      </div>

                      <span
                        className={`arrow ${
                          openAccordion === "subDivisions" ? "open" : ""
                        }`}
                      ></span>
                    </div>

                    <div className="accordion-content">
                      <ul>
                        {subDivisions.map((item, index) => (
                          <li key={index}>
                            <a
                              href="#"
                              onClick={() =>
                                handleOrganizationChange("subDivision", item)
                              }
                              className={
                                isValueExist(
                                  organization?.subDivision || [],
                                  item.value
                                )
                                  ? "active"
                                  : ""
                              }
                            >
                              {item.label}
                            </a>
                            <a
                              onClick={() => handleInfo(item.organization_code)}
                            >
                              <IoIosInformationCircleOutline />
                            </a>
                          </li>
                        ))}
                        {/* {organizations.subDivisions.map((item, index) => (
                                          <li key={index}><a href="#" onClick={() => handleOrganizationChange('subDivision', item)} className={isValueExist(organization?.subDivision || [], item.value) ? 'active' : ''}>{item.label}</a></li>
                                        ))} */}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <label>No sub division found</label>
              )}
            </li>
            <li>
              <button
                className="load-map-btn mt-3 w-100"
                onClick={handleOrganizationNetwork}
                type="button"
              >
                View Network
              </button>

              <button
                className="load-map-btn mt-3 w-100"
                onClick={handleClearNetwork}
                type="button"
              >
                Clear Network
              </button>
            </li>
          </ul>
        ) : (
          <label>No data found</label>
        )}
        <ul
          data-parent="network"
          className={`${subMenuSection == "network" ? "d-block" : "d-none"}`}
        >
          <li>
            <h6 className="sub-menu-title mb-0">Network</h6>
          </li>
          {/* {subStatations?.length ? ( */}
          <>
            <li>
              <div className="acoordion-wrap">
                <div
                  className={`accordion ${openAccordion === 4 ? "open" : ""}`}
                >
                  <div
                    className="accordion-header"
                    onClick={() => toggleAccordion(4)}
                  >
                    <h3>
                      <img src="Extra-High-Voltage.svg" alt="" /> EHV{" "}
                    </h3>
                    <span
                      className={`arrow ${openAccordion === 4 ? "open" : ""}`}
                    ></span>
                  </div>
                  <div className="accordion-content">
                    <ul>
                      {substationList.map((stnItem, index) => (
                        <li key={index}>
                          <a
                            href="#"
                            onClick={() => handleSubStatationChange(stnItem)}
                            className={
                              "is_selected" in stnItem && stnItem.is_selected
                                ? "active"
                                : ""
                            }
                          >
                            {stnItem.label}
                          </a>
                          <a onClick={() => setFlyname(stnItem.label)}>
                            <MdOutlineLocationOn />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            <li>
              {/* <form onSubmit={handleSubmit}>
                <button
                  className="load-map-btn mt-3 w-100"
                  // disabled={selectedProjects.length === 0}
                  type="submit"
                >
                  View Map
                </button>
              </form> */}

              <button
                className="load-map-btn mt-3 w-100"
                onClick={handleClearLineNetwork}
                type="button"
              >
                Clear Line Network
              </button>
            </li>
          </>
        </ul>

        <ul
          data-parent="base-map"
          className={`${subMenuSection == "base-map" ? "d-block" : "d-none"}`}
        >
          <li>
            <h6 className="sub-menu-title mb-0">Basemap</h6>
          </li>
          <li>
            <a
              href="#"
              className={`${activeLayer === "Canvas" ? "active" : ""}`}
              onClick={() => handleMapChange("Canvas")}
            >
              <img src="Open-Street-Map.svg" alt="" /> Canvas
            </a>
          </li>
          <li>
            <a
              href="#"
              className={`${activeLayer === "OpenStreetMap" ? "active" : ""}`}
              onClick={() => handleMapChange("OpenStreetMap")}
            >
              <img src="Open-Street-Map.svg" alt="" /> Open Street Map
            </a>
          </li>
          <li>
            <a
              href="#"
              className={`${activeLayer === "Satellite" ? "active" : ""}`}
              onClick={() => handleMapChange("Satellite")}
            >
              <img src="Satellite-image.svg" alt="" /> Satellite image
            </a>
          </li>
       
          <li>
            <a
              href="#"
              className={`${activeLayer === "BingMaps" ? "active" : ""}`}
              onClick={() => handleShowObject("draw-objects")}
            >
              <img src="Bing-Map.svg" alt="" /> User Define Layer
            </a>
{/*             
            <ul>
              <li>dsds</li>
              {drawGeoJSON.length > 0 && drawGeoJSON.features.map(item => (
    <li key={item.properties.id}>{item.properties.id} ggfg </li>
))}

           
       
            </ul> */}
          </li>
        </ul>

        <ul
          data-parent="base-layer"
          className={`${subMenuSection == "base-layer" ? "d-block" : "d-none"}`}
        >
          <li>
            <h6 className="sub-menu-title mb-0">Base Layer</h6>
          </li>

          <li>
            <div className="acoordion-wrap">
              <div
                className={`accordion ${
                  openblAccordion === "Gujarat-Boundary" ? "open" : ""
                }`}
              >
                <div
                  className="accordion-header"
                  onClick={() => toggleblAccordion("Gujarat-Boundary")}
                >
                  <h3>Gujarat Boundary</h3>

                  <span
                    className={`arrow ${
                      openblAccordion === "Gujarat-Boundary" ? "open" : ""
                    }`}
                  ></span>
                </div>

                <div className="accordion-content" style={{ paddingLeft: 0 }}>
                  {/* <ul> */}

                  {/* <li> */}

                  <div className=" d-flex justify-content-between">
                    <a
                      href="#"
                      className={`${isGujaratBoundary ? "active" : ""}`}
                      onClick={() => setIsGujaratBoundary(!isGujaratBoundary)}
                    >
                      {/* <img src="Open-Street-Map.svg" alt="" /> */}
                      Gujarat
                    </a>
                    <span className="ml-auto px-2" style={{ width: "46px" }}>
                      {" "}
                    </span>
                  </div>

                  <div className=" d-flex justify-content-between">
                    <a
                      href="#"
                      className={`${
                        baseLayer.includes("Districts") ? "active" : ""
                      }`}
                      onClick={() => handleBaseLayerChange("Districts")}
                    >
                      {/* <img src="Open-Street-Map.svg" alt="" /> */}
                      District
                    </a>
                    <span className="ml-auto px-2">
                      <StyleModal
                        setStyle={setDistrictStyle}
                        style={districtStyle}
                      />
                    </span>
                  </div>

                  {/* </li> */}

                  {/* <li> */}
                  <div className="d-flex justify-content-between">
                    <a
                      href="#"
                      className={`${
                        baseLayer.includes("Talukas") ? "active" : ""
                      } link-flex`}
                      onClick={() => handleBaseLayerChange("Talukas")}
                      disabled={!isTalukaVisible}
                    >
                      {/* <img src="Open-Street-Map.svg" alt="" /> */}
                      Taluka
                    </a>
                    {/* ${isTalukaVisible ? "" : "disabled"} */}
                    <span className={`ml-auto px-2 `}>
                      <StyleModal
                        setStyle={setTalukaStyle}
                        style={talukaStyle}
                      />
                    </span>
                  </div>
                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                  {/* </li> */}

                  <button
                    className="load-map-btn mt-3 w-100"
                    onClick={handleRefresh}
                    type="button"
                  >
                    Clear All
                  </button>

                  {/* </ul> */}
                </div>
              </div>
            </div>
          </li>

          <li>
            <div className="acoordion-wrap ">
              <div
                className={`accordion  ${
                  openblAccordion === "InfraStructure" ? "open" : ""
                }`}
              >
                <div
                  className="accordion-header mt-3"
                  onClick={() => toggleblAccordion("InfraStructure")}
                >
                  <h3>InfraStructure</h3>

                  <span
                    className={`arrow ${
                      openblAccordion === "InfraStructure" ? "open" : ""
                    }`}
                  ></span>
                </div>

                <div className="accordion-content" style={{ paddingLeft: 0 }}>
                  {/* <ul> */}

                  <div className="d-flex justify-content-between">
                    <a
                      href="#"
                      onClick={() =>
                        handleInfraStructureChange("gujarat_highway")
                      }
                      className={
                        infraStructure.includes("gujarat_highway")
                          ? "active"
                          : ""
                      }
                    >
                      Road Network{" "}
                    </a>
                    <span className="">
                      <StyleModal
                        style={highwayStyle}
                        setStyle={setHighwayStyle}
                      />
                    </span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <a
                      href="#"
                      onClick={() => handleInfraStructureChange("water_body")}
                      className={
                        infraStructure.includes("water_body") ? "active" : ""
                      }
                    >
                      Water Layer{" "}
                    </a>
                    <span className="">
                      <StyleModal style={waterStyle} setStyle={setwaterStyle} />
                    </span>
                  </div>

                  {/* </li> */}

                  <div className="d-flex justify-content-between">
                    <a
                      href="#"
                      onClick={() =>
                        handleInfraStructureChange("naturalReserve")
                      }
                      className={
                        infraStructure.includes("naturalReserve")
                          ? "active"
                          : ""
                      }
                    >
                      Natural Reserve Layer{" "}
                    </a>
                    <span className="">
                      <StyleModal
                        style={reserveStyle}
                        setStyle={setReserveStyle}
                      />
                    </span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <a
                      href="#"
                      onClick={() => handleInfraStructureChange("railway")}
                      className={
                        infraStructure.includes("railway") ? "active" : ""
                      }
                    >
                      Railway Layer{" "}
                    </a>
                    <span className="">
                      <StyleModal style={railStyle} setStyle={setRailStyle} />
                    </span>
                  </div>

                  <button
                    className="load-map-btn mt-3 w-100"
                    onClick={handleClearInfrastructure}
                    type="button"
                  >
                    Clear All
                  </button>

                  {/* </li> */}

                  {/* </ul> */}
                </div>
              </div>
            </div>
          </li>
        </ul>

        <ul
          data-parent="base-map"
          className={` mt-2 ${subMenuSection == "draw" ? "d-block" : "d-none"}`}
        >
          <li>
            <p className="sub-menu-title mb-0 fs-6 fw-bolder">Draw</p>
          </li>
          <li>
            <a
              href="#"
              className={measureMode === "point" ? "active" : ""}
              onClick={() => handleMeasureChange("point")}
            >
              Point
            </a>
          </li>

          <li>
            <a
              href="#"
              className={measureMode === "area" ? "active" : ""}
              onClick={() => handleMeasureChange("area")}
            >
              Area
            </a>
          </li>

          {/* <ul> */}
          <li>
            <h6 className="sub-menu-title mb-0 fs-6">Measument</h6>
          </li>
          <li>
            <a
              href="#"
              className={measureMode === "length" ? "active" : ""}
              onClick={() => handleMeasureChange("length")}
            >
              Length
            </a>
          </li>

          <li>
            <a
              href="#"
              className={measureMode === "circle" ? "active" : ""}
              onClick={() => handleMeasureChange("circle")}
            >
              Circle
            </a>
          </li>

          <li>
              <div className="acoordion-wrap mt-4">
                <div
                  className={`accordion ${
                    isFindDraw ? "open" : ""
                  }`}
                >
                  <div className="accordion-header">
                    <div
                      className="toggle"
                      onClick={() => setIsFindDraw(prev => !prev)}
                    >
                      <h3>
                        {/* <img src="Company.svg" alt="" /> */}
                         Find Draw Layer
                      </h3>
                    </div>
                    {/* <div>
                      {openAccordion === "company" && (
                        <StyleModal
                          setStyle={setCompanyStyle}
                          style={companyStyle}
                          setIsStyleModal={setIsStyleModal}
                        />
                      )}
                    </div> */}
                    <span
                      className={`arrow ${
                        isFindDraw ? "open" : ""

                      }`}
                    ></span>
                  </div>
                  </div>
                  </div>
</li>

{isFindDraw && (
          <li  className={`propertyform ${isFindDraw ? "open" : ""}`}>

  <>
              <div className="col-md-12">
                  <div className="mb-0">
                    {/* <label className="form-label1" htmlFor="name">
                      Object Id
                    </label> */}
                    <input
                      placeholder="Enter Id"
                      type="text"
                      id="object_id"
                      className="form-control form-control-xs"
                      value={objectId}
                      onChange={(e) => setObjectId(e.target.value)}
                    />

                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    style={{
                      padding: "8px 30px",
                      backgroundColor:'#6B8A7A', // Change color when disabled
                      borderRadius: "8px",
                      color: "white",
                      fontSize: "12px",
                      marginTop: "20px",
                      width: "200px",
                      // cursor: isSaved ? "not-allowed" : "pointer", // Change cursor when disabled
                      // opacity: isSaved ? 0.6 : 1, // Dull the button when disabled
                      border: "none", // Remove border if you want to keep it clean
                    }}
                    onClick={findDrawLayer}
                  
                  >
                  Find
                  </button>

                  <button
                    style={{
                      padding: "6px 20px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      color: "black",
                      fontSize: "12px",
                      marginTop: "20px",
                      marginLeft: "10px",
                      border: "1px solid black",
                      width: "200px",
                    }}
                    onClick={() => setIsFindDraw(prev => !prev)}
                  >
                    {!isSaved ? "Cancle" : "Hide"}
                  </button>
                </div></>

                {responseDraw != null && responseDraw?.features?.length > 0 && (
  <div className="none">
    <ul>
    <li >
 
            <p className=" mb-0  fw-bolder mt-4 mb-2" style={{color:'#254336'}}>Properties </p>
          </li>
          {responseDraw?.features?.length > 0 && (
  <div className="mt-4 p-1 rounded-md bg-gray-50">

    <div className="table-responsive">
      <table className="table table-bordered" style={{borderColor:'#6B8A7A'}}>
        <tbody>
          {responseDraw.features.map((item, index) => (
            <React.Fragment key={index}>
              <tr>
                <td className="fw-bold"> Id</td>
                <td>{item.properties.id}</td>
              </tr>
              <tr>
                <td className="fw-bold">Type</td>
                <td>{item.properties.type}</td>
              </tr>
              <tr>
                <td className="fw-bold">Document</td>
                <td>{item.properties.document_url !== "Null" ? "Yes" : "No"}</td>
              </tr>
              {/* Add a separator row for better readability */}
              <tr className="bg-light">
                {/* <td colSpan="2"></td> */}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
  </div>
      <div className="d-flex justify-content-between">
      <button
        style={{
          padding: "8px 30px",
          backgroundColor:'red', // Change color when disabled
          borderRadius: "8px",
          color: "white",
          fontSize: "12px",
          marginTop: "20px",
          width: "200px",
          // cursor: isSaved ? "not-allowed" : "pointer", // Change cursor when disabled
          // opacity: isSaved ? 0.6 : 1, // Dull the button when disabled
          border: "none", // Remove border if you want to keep it clean
        }}
        onClick={handleDeleteShape}
      
      >
      Delete
      </button>

      <button
        style={{
          padding: "6px 20px",
          backgroundColor: "white",
          borderRadius: "8px",
          color: "black",
          fontSize: "12px",
          marginTop: "20px",
          marginLeft: "10px",
          border: "1px solid black",
          width: "200px",
        }}
        onClick={() => setResponseDraw([])}
      >Cancel
        {/* {!isSaved ? "Cancle" : "Hide"} */}
      </button>
    </div>
    </div>
)}

    </ul>
  </div>
)}

          </li>

)}
{measureDetail && (
            <>
              <li className="mt-3">
                <h6 className="sub-menu-title mb-0 d-flex justify-content-between">
                  <span>{measureDetail.name}</span>
             {     measureDetail?.type && (measureDetail.type === 'area' || measureDetail.type === 'point') && ( 
                  <img
                    src="plusSVG.svg"
                    onClick={() => setIsForm(true)}
                    alt="plus icon"
                  />)}
                  {measureDetail.type != 'point'  && (
                  <span>
                    {measureDetail.measurement}{" "}
                    {measureDetail.type == "area" ||
                    measureDetail.type == "circle"
                      ? " km²"
                      : "km"}
                  </span>
                  )}
                </h6>
                {/* <h7 className="mb-0 text-center">Measurement: &nbsp;{measureResult}</h7> */}
              </li>

              <li className={`propertyform ${isForm ? "open" : ""}`}>
                {/* Content of the form goes here */}
                {/* <h1>Form</h1> */}
                {measureDetail.type == "point" && (
                  <>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label1" htmlFor="type">
                        Location Type
                      </label>
                      <select
                        className="form-select form-select-sm"
                        id="object_type"
                        value={formData2.object_type}
                        onChange={handleChange1}
                      >
                        <option value="">Select </option>
                        <option value="Solar Panel">Solar Panel</option>
                        <option value="Wind Mill">Wind Mill</option>
                        <option value="Communication Tower">
                          Communication Tower
                        </option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label1" htmlFor="type">
                      Area
                    </label>
                    <select
                      className="form-select form-select-sm"
                      id="type"
                      value={formData2.type}
                      // onChange={handleChange1}
                    >
                      <option value="">Select </option>
                      <option value="Solar Panel">Solar Panel</option>
                      <option value="Wind Mill">Wind Mill</option>
                      <option value="Communication Tower">
                        Communication Tower
                      </option>
                    </select>
                  </div> */}
                {/* </div> */}
                </>
                )}

{formData2.type == "area" && (
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label1" htmlFor="type">
                        Area Type
                      </label>
                      <select
                        className="form-select form-select-sm"
                        id="object_type"
                        value={formData2.object_type}
                        onChange={handleChange1}
                      >
                        <option value="">Select </option>
                        <option value="Solar Park">Solar Park</option>
                        <option value="Wind Park">Wind Park</option>
                        <option value="Switch Yard">Switch Yard</option>
                      </select>
                    </div>
                  </div>
                )}
    <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label1" htmlFor="name">
                      Name
                    </label>
                    <input
                      placeholder="Enter Name"
                      type="text"
                      id="object_name"
                      className="form-control form-control-sm"
                      value={formData2.name}
                      onChange={handleChange1}
                    />
                  </div>
                </div>
              
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label1" htmlFor="name">
                     Id
                    </label>
                    <input
                      placeholder="Enter Id"
                      type="text"
                      id="object_id"
                      className="form-control form-control-sm"
                      value={formData2.object_id}
                      onChange={handleChange1}
                    />
                  </div>
                </div>
     

            
              
                <div className="col-md-12 p-1">
                  <button
                    onClick={handleUploadModal}
                    style={{
                      padding: "6px 20px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      color: "black",
                      fontSize: "12px",
                      border: "1px solid black",
                      width: "100%",
                    }}
                  >
                    {" "}
                    Upload{" "}
                  </button>
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    style={{
                      padding: "8px 30px",
                      backgroundColor: isSaved ? "gray" : "#6B8A7A", // Change color when disabled
                      borderRadius: "8px",
                      color: "white",
                      fontSize: "12px",
                      marginTop: "20px",
                      width: "200px",
                      cursor: isSaved ? "not-allowed" : "pointer", // Change cursor when disabled
                      opacity: isSaved ? 0.6 : 1, // Dull the button when disabled
                      border: "none", // Remove border if you want to keep it clean
                    }}
                    onClick={SaveDraw}
                    disabled={isSaved}
                  >
                    {isSaved ? "Saved" : "Save"}
                  </button>

                  <button
                    style={{
                      padding: "6px 20px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      color: "black",
                      fontSize: "12px",
                      marginTop: "20px",
                      marginLeft: "10px",
                      border: "1px solid black",
                      width: "200px",
                    }}
                    onClick={() => {
                      setIsForm(false);
                    }}
                  >
                    {!isSaved ? "Cancle" : "Hide"}
                  </button>
                </div>
                {isError && (
                  <p style={{ color: "red", paddingTop: "5px" }}>
                    {" "}
                    Error: Data doesnot saved !{" "}
                  </p>
                )}
              </li>
            </>
          )}
          {/* </ul> */}
        </ul>

        <ul
          data-parent="objects"
          className={`${subMenuSection == "objects" ? "d-block" : "d-none"}`}
        >
          <li>
            <h6 className="sub-menu-title mb-0">Objects</h6>
          </li>

          {isshowssIds && isshowssIds.length > 0 &&
            pointTypesArray.map(([pointName, iconSrc], index) => (
              <li key={index} className="d-flex justify-content-between">
                <a
                  href="#"
                  onClick={() => handlePointChange(pointName)}
                  className={
                    selectedPointType.includes(pointName) ? "active" : ""
                  }
                >
                  {pointName}
                </a>
                {iconSrc ? (
                  <img
                    src={iconSrc}
                    alt={pointName}
                    style={{ width: "24px", height: "24px" }}
                  />
                ) : (
                  <span>No Icon</span>
                )}
              </li>
            ))}
        </ul>
        <ul
          data-parent="print"
          className={`${subMenuSection == "print" ? "d-block" : "d-none"}`}
        >
          <li>
            <h6 className="sub-menu-title mb-0">Print</h6>
          </li>
          <li>
            <a href="#" onClick={handleGeneratePDF}>
              <img src="Map-Printing-Options.svg" alt="" /> Map Printing Options
            </a>
          </li>
          <li>
            <a href="#" onClick={handleAreaPrint}>
              <img src="Map-Printing-Options.svg" alt="" /> Area Map Print
              {
                maploading ? (
                  <img src="loadingSpinner.svg" alt="" className="px-3" />
                ) : success ? (
                  <img src="successTick.svg" alt="" className="px-3" /> // Shows success tick after loading
                ) : (
                  <img src="unSuccessTick.svg" alt="" className="px-3" />
                ) // Shows success tick after loading
              }
            </a>
          </li>
        </ul>
        <button className="close-submenu" onClick={removeClass}>
          <img src="left-double-chevron.png" alt="" />
        </button>
      </div>

      {isInfoModal && subMenuSection == "organization" && (
        <InfoModel
          infoResult={infoResult}
          handleClose={handleClose}
          show={isStyleModal}
        />
      )}

      {isUploadModal && isForm && subMenuSection == "draw" && (
        <UploadModal
          handleClose={() => setIsUploadModal(false)}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
      )}
    </div>
  );
};

export default LeftSidebar;
