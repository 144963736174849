import React, { useState, useEffect, useRef } from "react";
import MapView from "./components/MapView";
import "./App.css";
import Login from "./components/Login";
// import html2canvas from 'html2canvas';

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const [authDetails, setAuthDetails] = useState({});
  const [user, setUser] = useState(null);
  const mapComponentRef = useRef(null);

  useEffect(() => {
    const authData = JSON.parse(queryParams.get("q"));
    // console.log("auth Data", authData)
    //  queryParams.set('q', null); // This won't affect the URL directly
    if (authData) {
      // console.log("check")
      // localStorage.removeItem('_auth');
      localStorage.setItem("_auth", JSON.stringify(authData));
      // console.log("auth", typeof authData)
      setAuthDetails(authData);
      const { pathname } = window.location;
      window.history.replaceState({}, "", pathname);
      // localStorage.setItem('_auth', JSON.stringify(authDetails));
      //refreshTokenOrUser(authData);
    } else {
      const storedAuth = localStorage.getItem("_auth");
      if (storedAuth) {
        refreshTokenOrUser(JSON.parse(storedAuth));
      }
    }
  }, []);

  const handleLogin = (authDetails) => {
    // console.log(authDetails);
    setAuthDetails(authDetails);
    localStorage.setItem("_auth", JSON.stringify(authDetails));
  };

  const refreshTokenOrUser = (authData) => {
    var currentTime = new Date();
    var targetTime = new Date(Date.parse(authData[".expires"]));

    // console.log(currentTime, targetTime);
    if (currentTime < targetTime) {
      // Calculate the difference in milliseconds
      const differenceMs = targetTime - currentTime;

      // Convert difference to hours
      const differenceHours = differenceMs / (1000 * 60 * 60);
      // If difference is less than or equal to 1 hour, set timeLeft state
      if (differenceHours > 1) {
        const fetchUserDetails = async () => {
          try {
            const userHeaders = new Headers();
            userHeaders.append("Accept", "application/json");
            userHeaders.append(
              "Authorization",
              `Bearer ${authData.access_token}`
            );
            const response = await fetch(
              process.env.REACT_APP_LOGIN_API_URL +
                "/api/Token/GetLoggedUserInfo?PROJCD=sfms",
              {
                method: "GET",
                headers: userHeaders,
                redirect: "follow",
              }
            );
            if (!response.ok) {
              throw new Error("Failed to fetch user details");
            }
            const userData = await response.json();
            setUser(userData);
          } catch (error) {
            console.error("Error fetching user details:", error);
          }
        };
        fetchUserDetails();
        // Time still left
        handleLogin(authData);
        const cleanUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, cleanUrl);
        console.log("Login success");
      } else {
        // No time left
        console.log("refresh token");
        const fetchData = async () => {
          const myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
          };

          try {
            const response = await fetch(
              process.env.REACT_APP_INTELLI_SCADA_API_URL +
                "/Token/Login2?reftoken=" +
                authData?.refresh_token,
              requestOptions
            );
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const result = await response.json();
            // console.log(result);
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
      }
    } else {
      console.log("token expired");
    }
  };

  return (
    <>
      <div className="App">
        {authDetails.access_token ? (
          <MapView />
        ) : (
          <Login onLogin={handleLogin} />
        )}
      </div>
    </>
  );
}

export default App;
