import React, { useState, useRef, useEffect, useCallback } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-easyprint/dist/bundle";
import "leaflet-geometryutil";


const defaultIcon = L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png",
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const selectedIcon = L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/1239/1239270.png",
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const warningIcon = L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/726/726488.png",
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const dangerIcon = L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/565/565547.png",
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const MapEvents = ({
  measureMode,
  drawPointData,
  setSelectedData,
  setMeasureDetail,
}) => {
  const map = useMap();

  const drawnItems = useRef(new L.FeatureGroup());
  const [drawing, setDrawing] = useState(false);
  const [currentLayer, setCurrentLayer] = useState(null);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [counter, setCounter] = useState({ point: 0, length: 0, area: 0, circle: 0 });
  const [layerNames, setLayerNames] = useState(new Map());

  // Initialize drawnItems feature group
  useEffect(() => {
    map.addLayer(drawnItems.current);
    return () => {
      map.removeLayer(drawnItems.current);
    };
  }, [map]);

  // Get layer name based on type
  const getLayerName = useCallback((type) => {
    let name = '';
    switch (type) {
      case 'point':
        name = `Point ${String.fromCharCode(65 + counter.point)}`;
        setCounter((prev) => ({ ...prev, point: prev.point + 1 }));
        break;
      case 'length':
        name = `Length ${String.fromCharCode(65 + counter.length)}`;
        setCounter((prev) => ({ ...prev, length: prev.length + 1 }));
        break;
      case 'area':
        name = `Area ${String.fromCharCode(65 + counter.area)}`;
        setCounter((prev) => ({ ...prev, area: prev.area + 1 }));
        break;
      case 'circle':
        name = `Circle ${String.fromCharCode(65 + counter.circle)}`;
        setCounter((prev) => ({ ...prev, circle: prev.circle + 1 }));
        break;
      default:
        break;
    }
    return name;
  }, [counter]);

  useEffect(() => {
    if (!map) return;
    // console.log('drawMap',drawPointData);
  
    map.eachLayer((layer) => {
// console.log(layer.options.id);

      if (layer instanceof L.Marker && layer.options.id) {
// console.log(layer.options.id);
        // Check if this marker is the selected one
        if (layer.options.id === drawPointData.point_name) {
          layer.setIcon(selectedIcon);
        } else {
          layer.setIcon(defaultIcon);
        }
      }
    });
  }, [drawPointData, map]); // Runs whenever selectedPointId changes
  const handleLayerClick = useCallback(({ layer, name, geom, measureMode, status }) => {
    // console.log('layer', measureMode, name);

    if (layer instanceof L.Marker) {
        let newIcon = null;

        switch (status) {
            case "selected":
                newIcon = selectedIcon;
                break;
            case "warning":
                newIcon = warningIcon;
                break;
            case "danger":
                newIcon = dangerIcon;
                break;
            default:
                newIcon = defaultIcon;
        }

        // Change only the selected marker's icon
        layer.setIcon(newIcon);
    }

    // Reset the previous selected layer's color
    if (selectedLayer && selectedLayer.setStyle) {
        selectedLayer.setStyle({ color: '#3388ff' }); // Default color
    }

    // If clicking on the same layer, deselect it
    if (selectedLayer && selectedLayer._leaflet_id === layer._leaflet_id) {
        drawnItems.current.removeLayer(layer);
        setSelectedLayer(null);
        return;
    }

    // Set the newly selected layer
    setSelectedLayer(layer);

    // Change the color of only the selected layer
    if (!(layer instanceof L.Marker) && layer.setStyle) {
        layer.setStyle({ color: 'red' });
    }

    // Reset all other layers to default color
    drawnItems.current.eachLayer((otherLayer) => {
        if (otherLayer !== layer && otherLayer.setStyle) {
            otherLayer.setStyle({ color: '#060708' }); // Default color
        }
    });

    // Determine measurement based on layer type
    let measurement = null;
    let type = '';

    if (layer instanceof L.Polygon) {
        type = 'area';
        measurement = (L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) / 1e6).toFixed(3); // km²
    } else if (layer instanceof L.Polyline) {
        type = 'length';
        measurement = (L.GeometryUtil.length(layer.getLatLngs()) / 1000).toFixed(3); // km
    } else if (layer instanceof L.Marker) {
        type = 'point';
        const { lat, lng } = layer.getLatLng();
        measurement = ''; // Convert object to string
    } else if (layer instanceof L.Circle) {
        type = 'circle';
        if(layer.getRadius() > 0 || isNaN(layer.getRadius()) ){
        const radiusInKm = layer.getRadius() / 1000; // km
 
          measurement = (Math.PI * radiusInKm * radiusInKm).toFixed(3); // Area in km²

        }else{
          measurement = 0;
        }
    }

    const detail = { name, geom, type, measurement };
    setMeasureDetail(detail);

}, [selectedLayer, setMeasureDetail]);

  
  useEffect(() => {
    if (!measureMode) return;

    if (drawing) finalizeCurrentLayer();

    // Override default vertex markers (corner points)
    L.Edit.Poly.prototype._createMarker = function (latlng, index) {
        let icon = L.divIcon({
            className: 'custom-vertex-icon',
            html: '<div style="width:8px; height:8px; background:green; border-radius:50%;"></div>',
            iconSize: [8, 8] // Make it smaller and round
        });

        let marker = new L.Marker(latlng, {
            draggable: true,
            icon: icon
        });

        marker._origLatLng = latlng;
        return marker;
    };

    // Create Draw Control
    const drawControl = new L.Control.Draw({
        position: 'topleft',
        edit: {
            featureGroup: drawnItems.current,
            poly: { allowIntersection: false },
        },
        draw: {
            polyline: measureMode === 'length' ? {
                shapeOptions: {
                    color: '#060708',
                    weight: 1,  // Smaller width
                    opacity: 0.8
                }
            } : false,
            polygon: measureMode === 'area' ? {
                shapeOptions: {
                    color: '#060708',
                    weight: 1,
                    opacity: 0.8
                }
            } : false,
            circle: measureMode === 'circle' ? {
                shapeOptions: {
                    color: '#060708',
                    weight: 1,
                    opacity: 0.8
                }
            } : false,
            marker: measureMode === 'point',
            rectangle: false,
            circlemarker: false,
        },
    });

    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, (e) => {
        const layer = e.layer;
        const name = getLayerName(measureMode);

        layer.bindTooltip(name, { permanent: true, direction: 'top' }).addTo(map);
        drawnItems.current.addLayer(layer);
        setLayerNames((prev) => new Map(prev).set(layer._leaflet_id, name));

        // console.log('latlng', layer);
        const latlngs =
            layer instanceof L.Circle ? layer.getLatLng() :
            layer instanceof L.Polygon ? layer.getLatLngs()[0] :
            layer instanceof L.Polyline ? layer.getLatLngs() :
            layer.getLatLng(); 

        const geom = createGeometry(latlngs, measureMode);
        layer.on('click', () => handleLayerClick({ layer, name, geom, measureMode }));

        setSelectedData({ layername: name, geometry: geom });
        setCurrentLayer(layer);
    });

    return () => {
        map.removeControl(drawControl);
        map.off(L.Draw.Event.CREATED);
    };
}, [map, measureMode, drawing, getLayerName, handleLayerClick, setSelectedData]);


  // Finalize current layer
  const finalizeCurrentLayer = useCallback(() => {
    if (currentLayer) {
      setDrawing(false);
      setCurrentLayer(null);
    }
  }, [currentLayer]);

  // Create geometry in WKT format
  const createGeometry = useCallback((latlngs, type) => {
    let geom = "";
    if (type === "length") {
      geom = `LINESTRING(${latlngs.map(({ lat, lng }) => `${lng.toFixed(6)} ${lat.toFixed(6)}`).join(', ')})`;
    } else if (type === "point") {
      geom = `POINT(${latlngs.lng.toFixed(6)} ${latlngs.lat.toFixed(6)})`;
    } else if (type === "area") {
      const closedLatlngs = [...latlngs, latlngs[0]]; // Close the polygon
      geom = `POLYGON((${closedLatlngs.map(({ lat, lng }) => `${lng.toFixed(6)} ${lat.toFixed(6)}`).join(', ')}))`;
    } else if (type === "circle") {
      const center = latlngs;
      geom = `POINT(${center.lng.toFixed(6)} ${center.lat.toFixed(6)})`;
    }
    return geom;
  }, []);

  return null;
};

export default MapEvents;
