import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet.markercluster";
// import PropTypes from "prop-types";
import ReactDOM from "react-dom/client"; // For React 1
import axios from "axios";


const PopUpContainer = ({
  name,

  isshowssIds,
  substationId,
  setColor,
  color,
  onHandleSSIds,
  onHandleLineCheck,
  onHandleCategory
}) => {
  // console.log(onHandleLineCheck());
  const [isLineCheck, setIsLineCheck] = useState(() => onHandleLineCheck(substationId));
  const [isCategory, setIsCategory] = useState({});
  const [lineColor, setLineColor] = useState(color[substationId] || "#000000");
  const [isApplied, setIsApplied] = useState(false);
  const [information, setInformation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectAll,setSelectAll] = useState(true);

  // Checkbox Handler for HV Network
  const handleLineCheckbox = (event) => {
    setIsApplied(false);
    setIsLineCheck(event.target.checked);
  };


  

  const handleCategoryCheckbox = (event, category) => {
    setIsApplied(false);
  
    setIsCategory((prev) => {
      const updatedCategory = {
        ...prev,
        [category]: event.target.checked,
      };
      const newCategory = Object.keys(updatedCategory)  // Get all keys (categories)
  .filter(key => updatedCategory[key]); 
  
      // Check if all categories are selected
      const allSelected = newCategory.length === information.length;
      setSelectAll(allSelected); // Set selectAll based on the updated state
  
      return updatedCategory;
    });
  };
// Apply Filter Logic
const applyFilter = () => {
  setColor((prevColors) => ({
    ...prevColors,
    [substationId]: lineColor,
  }));

  // const selectedssIds = isLineCheck
  //   ? [...isshowssIds, substationId] // Add if not present
  //   : isshowssIds.filter((id) => id !== substationId); // Remove if unchecked

    // console.log("updatedIds slected",selectedssIds);
  onHandleSSIds(substationId); // Pass the computed array
const newCategory = Object.keys(isCategory)  // Get all keys (categories)
  .filter(key => isCategory[key]);            // Filter to only true values  
// console.log("isCategory",newCategory);

  onHandleCategory({ substaionId: substationId, category: newCategory})
  setIsApplied(true);
};


  // Handle Color Change
  const handleColorChange = (event) => {
    setLineColor(event.target.value);
    setIsApplied(false);
  };

  useEffect(() => {
    if (!substationId) return;
  
    const timer = setTimeout(() => {
      setLoading(true);
      axios.get(`https://geo-tag.nividasoftware.com/api/substation/${substationId}/categories`)
        .then((result) => {
          setInformation(result.data);
          if (selectAll) {
            setIsCategory((prev) => {
              const newCategories = {};
              result.data.forEach((item) => {
                newCategories[item.category] = prev[item.category] ?? true;
              });
              return newCategories;
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching project information:", error);
        });
    }, 500); // Debounce to avoid excessive requests
  
    return () => clearTimeout(timer);
  }, [substationId]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // If 'selectAll' is checked, select all categories
      setIsCategory((prev) => {
        const newCategories = {};
        information.forEach((item) => {
          newCategories[item.category] = true; // Mark all categories as selected
        });
        return newCategories;
      });
    } else {
      // If 'selectAll' is unchecked, deselect all categories
      setIsCategory({});


    }
  
    // Set the 'selectAll' state to the checkbox value
    setSelectAll(event.target.checked);
    setIsApplied(false);
  };
  
  // if(return )
  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        padding: "10px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h5 style={{ textAlign: "left", fontSize: "16px", fontWeight: "600", marginBottom: "10px" }}>
        Location: <span style={{ fontSize: "12px", color: "#555" }}>{name} &nbsp;({substationId})</span>
      </h5>

      {/* Information Section */}
      <div style={{ marginBottom: "15px",  }}>
        <div className="d-flex justify-content-between">
        <div style={{ fontWeight: "bold", marginBottom: "5px" }}>Information</div>
        <div style={{ fontWeight: "bold", marginBottom: "5px" }}> <input
          type="checkbox"
          checked={selectAll}
          onChange={handleSelectAll}
        /></div></div>
        {loading ? (
          "Loading..."
        ) : (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd", fontSize: "14px" }}>
                  Category
                </th>
                <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd", fontSize: "14px" }}>
                  Count
                </th>
              
                <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd", fontSize: "14px" }}>
                  Category
                </th>
                <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd", fontSize: "14px" }}>
                  Count
                </th>
              </tr>
            </thead>
            <tbody>
            {information.length > 0 &&
                information.reduce((rows, item, index) => {
                  if (index % 2 === 0) {
                    rows.push([item]);
                  } else {
                    rows[rows.length - 1].push(item);
                  }
                  return rows;
                }, []).map((pair, rowIndex) => (
                  <tr key={rowIndex}>
                    {pair.map((item, colIndex) => (
                      <React.Fragment key={colIndex}>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={(e) => handleCategoryCheckbox(e, item.category)}
                            checked={isCategory[item.category] || false}
                            className="m-1 mt-2"
                          />
                          <span style={{ fontWeight: "bold", fontSize: "14px" }}>{item.category}</span>
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>{item.count}</span>
                        </td>
                      </React.Fragment>
                    ))}
                    {pair.length === 1 && (
                      <>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                          }}
                        ></td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                          }}
                        ></td>
                      </>
                    )}
                  </tr>
                ))}

            </tbody>
          </table>
        )}
      </div>

      {/* Filters Section */}
      <div>
        <p style={{ fontWeight: "bold" }}>Filters</p>
        <table style={{ width: "100%", borderCollapse: "collapse", transform: "translateY(-10px)" }}>
          <tbody>
            {/* <tr>
              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>HV Network</span>
              </td>
              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                <input
                  type="checkbox"
                  onChange={handleLineCheckbox}
                  checked={isLineCheck}
                  className="m-1 mt-2"
                />
              </td>
            </tr> */}

            <tr>
              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>Line Color</span>
              </td>
              <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                <input
                  type="color"
                  id="colorPicker"
                  name="colorPicker"
                  value={lineColor}
                  onChange={handleColorChange}
                  className="rounded-circle"
                  style={{
                    backgroundColor: lineColor,
                    width: "20px",
                    height: "20px",
                    transform: "translateY(2px)",
                  }}
                />
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <button
                  className={`btn m-1 mx-1 ${isApplied ? "disabled" : ""}`}
                  style={{ float: "right", width: "100%", backgroundColor:'#6B8A7A' , color:'white' }}
                  onClick={applyFilter}
                >
                  {!isApplied ? "Apply" : "Applied"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
const MarkerCluster = ({
  data,
  renderPopup = null, // Default value for renderPopup
  flyname = null, // Default value for flyname

  isshowssIds,
  onHandleLineCheck,
  color,
  setColor,
  onHandleSSIds,
  onHandleCategory
}) => {
  const map = useMap();
  const [isChecked, setIsChecked] = useState({});

  useEffect(() => {
    if (!map || !data?.features?.length) return;

    const markers = L.markerClusterGroup({
      chunkedLoading: true,
      animate: true,
      animateAddingMarkers: true,
      spiderfyOnMaxZoom: false,
    });

    let flyToMarker = null;

    data.features.forEach((feature) => {
      const [lng, lat] = feature.geometry.coordinates;
      const name = feature.properties.name;
      const substationId = feature.properties.substation_id;
      
      const marker = L.marker([lat, lng]);

      marker.bindTooltip(name, {
        permanent: true,
        direction: "top",
        className: "custom-label",
        offset: [-15, 0],
      });

      const popupContainer = document.createElement("div");

      ReactDOM.createRoot(popupContainer).render(
        <PopUpContainer
          name={name}
          onHandleLineCheck={onHandleLineCheck}
          substationId={substationId}
          isshowssIds={isshowssIds}
          color={color}
          setColor={setColor}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          onHandleSSIds={onHandleSSIds}
          onHandleCategory={onHandleCategory}
        />
      );

      marker.bindPopup(popupContainer, {
        autoClose: false,
        direction: "bottom",
        offset: [0, 10],
        keepInView: true,
      });

      marker.on("popupopen", () => marker.unbindTooltip());
      marker.on("popupclose", () =>
        marker.bindTooltip(name, {
          permanent: true,
          direction: "top",
          className: "custom-label-close",
          offset: [-15, 0],
        })
      );

      if (flyname && name === flyname) {
        flyToMarker = marker;
      }

      markers.addLayer(marker);
    });

    map.addLayer(markers);

    if (flyToMarker) {
      map.flyTo(flyToMarker.getLatLng(), 15, {
        duration: 1.5,
        easeLinearity: 0.25,
      });

      flyToMarker.openPopup();
    }

    markers.on("clusterclick", (event) => {
      map.flyToBounds(event.layer.getBounds(), {
        padding: [50, 50],
        duration: 1.5,
        easeLinearity: 0.25,
      });
    });

    return () => {
      map.removeLayer(markers);
    };
  }, [map, data, renderPopup, flyname]);

  return null;
};


export default MarkerCluster;
