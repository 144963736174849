import React, { useState } from 'react';

function FindPlace({ setFindPlaceModal, setPlaceGeoJson, findPlaceModal }) {
  const [placeName, setPlaceName] = useState('');

  const getLatLong = async () => {
    if (!placeName.trim()) {
      alert("Please enter a place name.");
      return;
    }

    const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${placeName}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
          // Filter results to include only those in Gujarat
    const gujaratResults = data.filter(place => 
      place.display_name.includes("Gujarat")
    );

      if (gujaratResults.length > 0) {
        const geojsonData = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: { name: gujaratResults[0].name},
              geometry: { 
                type: "Point", 
                coordinates: [parseFloat(gujaratResults[0].lon), parseFloat(gujaratResults[0].lat)] 
              },
            },
          ],
        };

        setPlaceGeoJson(geojsonData);
        console.log("Updated GeoJSON:", geojsonData);
      } else {
        alert("Specified location was not found or is located outside Gujarat!");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  if (!findPlaceModal) return null; // Ensure the modal is only rendered when needed

  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "65px",
        zIndex: 1000,
        background: "white",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        padding: "10px",
        width: "230px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #ddd",
          paddingBottom: "5px",
          marginBottom: "8px",
        }}
      >
        <h6 style={{ margin: 0, fontSize: "14px",color:'#254336', fontWeight: "600" }}>
          Search by Name
        </h6>
        <button
          type="button"
          onClick={() => setFindPlaceModal(false)}
          style={{
            background: "none",
            border: "none",
            fontSize: "16px",
            cursor: "pointer",
            color: "#555",
          }}
        >
          ✖
        </button>
      </div>

      <input
        type="text"
        placeholder="e.g., Gandhinagar"
        value={placeName}
        onChange={(e) => setPlaceName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            getLatLong(); // Call function on Enter
          }}}
        style={{
          width: "100%",
          padding: "6px",
          fontSize: "12px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          marginBottom: "8px",
        }}
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={getLatLong}
          style={{
            background: "#6B8A7A",
            color: "white",
            padding: "6px 15px",
            border: "none",
            borderRadius: "20px",
            fontSize: "12px",
            marginRight: "5px",
            cursor: "pointer",
          }}
        >
          Search
        </button>
        <button
          onClick={() => setFindPlaceModal(false)}
          style={{
            background: "white",
            color: "#254336",
            padding: "6px 15px",
            border: "1px solid #254336",
            borderRadius: "20px",
            fontSize: "12px",
            cursor: "pointer",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default FindPlace;
