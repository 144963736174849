// geoDataUtils.js
import axios from "axios";

// Create a throttling wrapper to debounce requests
let throttleTimeout = null;

export const fetchObjectData = (
    categorywisteFilter,
    pointTypesData,
    setFilteredObject
) => {
    // Clear any existing timeout to ensure only one execution per 3 seconds
    if (throttleTimeout) {
        clearTimeout(throttleTimeout);
    }

    // Throttle execution by 3 seconds
    throttleTimeout = setTimeout(async () => {
        // console.log(isshowsids);
        try {
        //   const projectIds = JSON.stringify(isshowsids.join(",")); // Fixing syntax
          const isshowsids = categorywisteFilter.map(item => item.substaionId)


            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            const formdataAPI = new FormData();
            formdataAPI.append("sub_station_ids", isshowsids);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdataAPI,
                redirect: "follow",
            };

            const projectResponse = await fetch(
                process.env.REACT_APP_API_URL + "/api/substations/projects",
                requestOptions
            );
            const responseData = await projectResponse.json()

            // Extract project IDs from the response
            // const projectData = responseData || [];
            const projectids = responseData.map((item) => item.project_id);

            // console.log(
            //     "Project Data:",
            //     projectData,
            //     "Project IDs:",
            //     projectids
            // );

            if (projectids.length === 0) {
                console.log("No project IDs matched the criteria.");
                setFilteredObject([]); // Set an empty result if no matches
                return;
            }

            // Create CQL filter strings
            const projectIdsCql = projectids.map((id) => `'${id}'`).join(",");
            const pointTypesCql = pointTypesData
                .map((type) => `'${type}'`)
                .join(",");

                const cqlFilters = categorywisteFilter
                .map(({ substaionId, category }) => {
              console.log('categorywisteFilter',category,substaionId);
          
                  const categoryFilter = category.map(cat => `'${cat}'`).join(",");
                  return `substation_id = ${substaionId} AND category IN (${categoryFilter})`;
                })
                .join(" OR ");  // Combine each filter for different substaionId/category pairs with OR
          
      // Combine the project_id, point_type filters with the previously generated category filters
const cqlFilter = `project_id IN (${projectIdsCql}) AND point_type IN (${pointTypesCql}) AND ${cqlFilters}`;

            // console.log("CQL Filter:", cqlFilter);

            // Fetch filtered GeoJSON data from the GeoServer
            const geoResponse = await axios
                .get("https://geoserver.fornaxenergytech.com/geoserver/wfs", {
                    params: {
                        service: "WFS",
                        version: "1.0.0",
                        request: "GetFeature",
                        typeName: "Gis_Gujarat:location_point",
                        outputFormat: "application/json",
                        cql_filter: cqlFilters,
                    },
                })
                .catch((e) => {
                    console.error(
                        "Error while fetching GeoServer data:",
                        e.message
                    );
                    return null;
                });

            if (!geoResponse || !geoResponse.data) {
                console.log("No data received from the GeoServer.");
                setFilteredObject([]); // Clear filtered data on error
                return;
            }
            // console.log(geoResponse.data);

            // Extract features from the GeoServer response
            const features = geoResponse.data?.features || [];
            setFilteredObject(features);

            // console.log("Filtered Data:", features);
        } catch (error) {
            console.error("Unexpected error:", error.message);
            setFilteredObject([]); // Set empty data on error
        }
    }, 3000); // 3-second delay
};
