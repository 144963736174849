import React from 'react';
import { useMap, Marker, Popup } from "react-leaflet";
import { useState, useEffect } from "react";
import L from "leaflet";

const customIcon = new L.Icon({
  iconUrl: "/dark-location-marker.svg",
  iconSize: [30, 30],
});

const LatLngPicker = ({
  isPickingLatLng,
  setIsPickingLatLng,
  handleLatLngSelect,
}) => {
  const map = useMap();
  const [selectedLatLng, setSelectedLatLng] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const [copied, setCopied] = useState(null);

  useEffect(() => {
    if (isPickingLatLng) {
      const handleMapClick = (e) => {
        const { lat, lng } = e.latlng;
        setSelectedLatLng([lat, lng]);
        handleLatLngSelect([lat, lng]);
        setShowPopup(true);
      };

      map.on("click", handleMapClick);

      return () => {
        map.off("click", handleMapClick);
      };
    }
  }, [isPickingLatLng, map, handleLatLngSelect]);

  const handleCopyToClipboard = (isPlain) => {
    if (selectedLatLng) {
      const latLngString = isPlain
        ? `${selectedLatLng[0]}, ${selectedLatLng[1]}`
        : `Latitude: ${selectedLatLng[0]}, Longitude: ${selectedLatLng[1]}`;

      navigator.clipboard.writeText(latLngString).then(() => {
        setSelectedLatLng(null);
        setShowPopup(false);
        setShowInstructions(false);
        setIsPickingLatLng(false);
      });
    }
  };

  const handleCopy = (isPlain) => {
    handleCopyToClipboard(isPlain);
    setCopied(isPlain ? "plain" : "clipboard");

    setTimeout(() => setCopied(null), 2000);
  };

  return (
    <>
      {isPickingLatLng && showInstructions && (
        <div style={instructionStyle} onClick={(e) => e.stopPropagation()}>
          <span>Click on the map to pick a location.</span>
          <button
            onClick={() => {
              setIsPickingLatLng(false);
              setShowInstructions(false);
            }}
            style={cancelButtonStyle}
          >
            Cancel
          </button>
        </div>
      )}

      {selectedLatLng && (
        <Marker
          position={selectedLatLng}
          icon={customIcon}
          eventHandlers={{
            click: () => setShowPopup(true),
          }}
        >
          {showPopup && (
            <Popup>
              <div className="text-center p-2">
                <p className="m-0">
                  <strong>Selected Latitude:</strong> {selectedLatLng[0]}
                </p>
                <p className="m-0">
                  <strong>Selected Longitude:</strong> {selectedLatLng[1]}
                </p>

                <button
                  onClick={() => handleCopy(false)}
                  className="btn btn-sm btn-primary m-2"
                >
                  {copied === "clipboard" ? "✅" : "📋"} Copy to Clipboard
                </button>
                <button
                  onClick={() => handleCopy(true)}
                  className="btn btn-sm btn-primary m-2"
                >
                  {copied === "plain" ? "✅" : "📋"} Copy Plain
                </button>
              </div>
            </Popup>
          )}
        </Marker>
      )}
    </>
  );
};

const instructionStyle = {
  position: "absolute",
  top: "10px",
  left: "50%",
  transform: "translateX(-50%)",
  background: "rgba(0, 0, 0, 0.7)",
  color: "white",
  padding: "8px 12px",
  borderRadius: "5px",
  fontSize: "14px",
  zIndex: 9999,
  display: "flex",
  alignItems: "center",
  pointerEvents: "auto",
};

const cancelButtonStyle = {
  marginLeft: "10px",
  padding: "5px 10px",
  backgroundColor: "#f44336",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  pointerEvents: "auto",
};

export default LatLngPicker;
