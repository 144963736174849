import React, { useState, useRef, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';

const TopNavbar = ({ organizations,setSearchId, setSearchData }) => {
  const [formData, setFormData] = useState({ project_id: '', category: ''});

  const [suggestions, setSuggestions] = useState([]);
  const [showCategories, setShowCategories] = useState(false);

  const inputRef = useRef(null);

  const categories = ['Company', 'Circle', 'Division', 'Sub Division'];

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.shiftKey && event.key === "1") {
  //       setFormData((prev) => ({ ...prev, category: "Company" }));
  //       // console.log("Category set to company");
  //     }else if ( event.key === "2"){
  //       setFormData((prev) => ({ ...prev, category: "Circle" }));
  //     }else if (event.shiftKey && event.key === "3"){
  //       setFormData((prev) => ({ ...prev, category: "Division" }));
  //     }else if (event.shiftKey && event.key === "4"){
  //       setFormData((prev) => ({ ...prev, category: "Sub Division" }));
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);


  const handleChange = (e, { newValue }) => {
    setFormData(prevState => ({
      ...prevState,
      project_id: newValue
    }));
    
    
    // Show or hide categories based on formData.category
    setShowCategories(formData.category === '');
  };

  const handleCategoryClick = (category) => {
    setFormData((prevData) => ({
      ...prevData,
      category,
      project_id: ''  // Reset project_id
    }));
    setShowCategories(false);
  };
  

  const handleFocus = () => {
    if (!formData.category) {
      setShowCategories(true);
    }
  };

  const handleBlur = () => {
    setTimeout(() => setShowCategories(false), 100); // Timeout to allow click events to register
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form Data", formData);

    try {
      let filtered = [];
      const { project_id, category } = formData;

      switch (category) {
        case "Company":
          filtered = organizations.companies.filter(filter => filter.label === project_id);
          setSearchId(filtered.organization_code);
          break;
        case "Circle":
          filtered = organizations.circle.filter(filter => filter.label === project_id);
          setSearchId(filtered.organization_code);
          break;
        case "Division":
          filtered = organizations.divisions.filter(filter => filter.label === project_id);
          // setInfraStructure(filtered.organization_code);

          break;
        case "Sub Division":
          filtered = organizations.subDivisions.filter(filter => filter.label === project_id);
          // setInfraStructure(filtered.organization_code);

          break;
        default:
          window.alert("Data not Found!");
          return;

      }
      setSearchData({project_id: filtered[0], category: formData.category })

      // console.log("Filtered data:", filtered);
    setFormData({ project_id: '', category: formData.category });

    } catch (error) {
      console.error('Error fetching data:', error);
    }

    // setFormData(prevState => ({
    //   ...prevState,
    //   project_id: newValue
    // }));
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (value.length < 3) {
      setSuggestions([]);
      return;
    }

    const filteredSuggestions = getFilteredSuggestions(value);
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getFilteredSuggestions = (value) => {
    const { category } = formData;
    if (!category) return [];

    const lowerValue = value.toLowerCase();
    const filterFn = filter => filter.label.toLowerCase().includes(lowerValue);

    switch (category) {
      case "Company":
        return organizations.companies.filter(filterFn);
      case "Circle":
        return organizations.circle.filter(filterFn);
      case "Division":
        return organizations.divisions.filter(filterFn);
      case "Sub Division":
        return organizations.subDivisions.filter(filterFn);
      default:
        return [];
    }
  };

  const getSuggestionValue = (suggestion) => suggestion.label;

  const renderSuggestion = (suggestion) => (
    <div className="suggestion-item-label">
      {suggestion.label}
    </div>
  );

  const handleKeyDown = (e) => {
    if (e.key === 'Tab' && suggestions.length > 0) {
      e.preventDefault();
      const firstSuggestion = suggestions[0];
      setFormData(prevState => ({
        ...prevState,
        project_id: firstSuggestion.label
      }));
      setSuggestions([]);
    }
  };

  const getPlaceholder = () => {
    switch (formData.category) {
      case 'Company':
        return 'Search for a company...';
      case 'Circle':
        return 'Search for a circle...';
      case 'Division':
        return 'Search for a division...';
      case 'Sub Division':
        return 'Search for a sub division...';
      default:
        return 'Search...';
    }
  };

 
  const handleShowCategory = () =>{
    setShowCategories(true);
  }

  // console.log("setFormData",formData);
  return (
    <nav className="top-navbar">
      <div className="top-navbar-logo">
        <img src="IntelliGIS.png" style={{ width: "150px", height: "50px" }} title="IntelliGIS" alt="IntelliGIS Logo" />
      </div>
      <form className="main-search" onSubmit={handleSubmit}>
        <div className='form-inline'>
          {formData.category && (
            <a onClick={handleShowCategory}>
            <div className="category-button">
              {formData.category}
            </div>
            </a>
          )}
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              ref: inputRef,
              type: "text",
              name: "project_id",
              value: formData.project_id,
              onChange: handleChange,
              onFocus: handleFocus,
              onBlur: handleBlur,
              onKeyDown: handleKeyDown,
              placeholder: getPlaceholder()

            }}
            theme={{
              suggestionsContainer: 'suggestions-container',
              suggestion: 'suggestion-item',
              suggestionHighlighted: 'suggestion-item-highlighted'
            }}
            className='search-input'
          />
          <button type="submit" className='search-btn'></button>
        </div>
        {showCategories && (
          <div className='categories-dropdown'>
            {categories.map((category, index) => (
              <div className="border" key={index} onMouseDown={() => handleCategoryClick(category)}>
                {category}
              </div>
            ))}
          </div>
        )}
      </form>
      <div className="top-navbar-links">
        {/* Add additional navbar links or buttons here */}
        <img src="atlas-right-logo.png" style={{ width: "150px", height: "50px"}} title="IntelliGIS" alt="IntelliGIS Logo" />
      </div>
    </nav>
  );
};

export default TopNavbar;
